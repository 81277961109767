import React from "react";
import { onlineBiddingData } from "../../components/work/WorkpageT1Data";
import WorkpageT1 from "../../components/work/WorkpageT1";
export default function OnlineBidding() {
  return (
    <>
      {onlineBiddingData.map((work13) => (
        <WorkpageT1
          bgImg={work13.bgImg}
          title={work13.title}
          heading={work13.heading}
          boximg={work13.boximg}
          purposedescription={work13.purposedescription}
          benefitTitle={work13.benefitTitle}
          benefitsdescriptions={work13.benefitsdescriptions}
          benefits={work13.benefits}
          featureHeading={work13.featureHeading}
          featuredescription={work13.featuredescription}
          feature={work13.feature}
        />
      ))}
    </>
  );
}
