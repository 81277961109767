import React from "react";

import "./splash.scss";
import ProgressBar from "../../components/progress/progress";

export const Splash = ({ children }) => {
  const [counter, setCounter] = React.useState(0);
  const [done, setDone] = React.useState(false);

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (counter < 100) {
        setCounter(counter + 2);
      } else {
        clearInterval(interval);
        setDone(true);
      }
    }, 40);
    return () => clearInterval(interval);
  }, [counter]);
  // const strokeColor = "#000"; // Initial stroke color
  const fillColor = `hsl(${counter}, 100%, 50%)`; // Dynamic fill color based on counter

  return done ? (
    <>{children}</>
  ) : (
    <div className="splash-screen">
      <h1>{counter}</h1>
      <div className="progress-bar">
        <div className="progress-bar-div">
          <h2 style={{ "--fill-color": fillColor }}>SUBLIME&nbsp;</h2>
          <h2>TECHNOCORP</h2>
          <h2>&nbsp;PVT&nbsp;LTD</h2>
        </div>
        <ProgressBar value={counter} />
      </div>
    </div>
  );
};
