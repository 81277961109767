import React from "react";
import { solarWebAppData } from "../../components/work/WorkpageT1Data";
import WorkpageT1 from "../../components/work/WorkpageT1";

export default function SolarWebApp() {
  return (
    <>
      {solarWebAppData.map((work14) => (
        <WorkpageT1
          title={work14.title}
          heading={work14.heading}
          bgImg={work14.bgImg}
          boximg={work14.boximg}
          purposedescription={work14.purposedescription}
          benefitTitle={work14.benefitTitle}
          benefits={work14.benefits}
          featureHeading={work14.featureHeading}
          featuredescription={work14.featuredescription}
          feature={work14.feature}
        />
      ))}
    </>
  );
}
