import React from "react";
import { ReactComponent as FooterLine } from "../../assets/icons/FooterLine.svg";
import "./Footer.scss";
export default function FooterPage() {
  return (
    <footer className="footer-page">
      <div className="footer-content">
        <div className="footer-column">
          <ul>
            <h4>Sitemap</h4>
            <FooterLine />
          </ul>
          <ul type="none">
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a href="/about-us">About Us</a>
            </li>
            <li>
              <a href="/work">Work</a>
            </li>
            <li>
              <a href="/careers">Careers</a>
            </li>
            <li>
              <a href="/contact-us">Contact Us</a>
            </li>
            <li>
              <a href="/privacy-policy">Privacy Policy</a>
            </li>
          </ul>
        </div>
        <div className="footer-column">
          <ul>
            <h4>Services</h4>
            <FooterLine />
          </ul>
          <ul type="none">
            <li>
              <a href="/services/cloud-and-digital-transformation-service">
                Cloud Transformation
              </a>
            </li>
            <li>
              <a href="/services/dedicated-developers">Dedicated Developers</a>
            </li>
            <li>
              <a href="/services/custom-software-development">
                Custom Software Development
              </a>
            </li>
            <li>
              <a href="/services/product-development-services">
                Product Development Services
              </a>
            </li>
            <li>
              <a href="/services/data-analytics-services">
                Data Analytics Services
              </a>
            </li>
          </ul>
        </div>
        <div className="footer-column">
          <ul>
            <h4>Works</h4>
            <FooterLine />
          </ul>
          <ul type="none">
            <li>
              <a href="/works/underground-mines-erp">Under Ground Mines ERP</a>
            </li>
            <li>
              <a href="/works/ai-based-health-monitoring-system">
                AI Based Health Monitoring
              </a>
            </li>
            <li>
              <a href="/works/solar-web-application">Solar Web Application</a>
            </li>
            <li>
              <a href="/works/online-bidding-facilitating">
                Online Bidding Facilitating
              </a>
            </li>
            <li>
              <a href="/works/material-testing-lab-automation">
                Material Testing & Lab Automation
              </a>
            </li>
            <li>
              <a href="/works/farmers-connect-application">
                Farmers Connect Application
              </a>
            </li>
            <li>
              <a href="/works/shipping-management-solution">
                Shipping Management Solution
              </a>
            </li>
            <li>
              <a href="/works/magnum-facility-management-erp">
                Magnum Facility Management ERP
              </a>
            </li>
          </ul>
        </div>
        <div className="footer-column">
          <ul>
            <h4>Works</h4>
            <FooterLine />
          </ul>
          <ul type="none">
            <li>
              <a href="/works/digital-ngo-platform">Digital NGO Platform</a>
            </li>
            <li>
              <a href="/works/bridgit-parenting-schooling">
                Bridgit Parenting & Schooling App
              </a>
            </li>
            <li>
              <a href="/works/profitability-implementation-tracker">
                Profitability & Implementation Tracker
              </a>
            </li>
            <li>
              <a href="/works/sublime-client-management-system">
                Sublime Client Management System
              </a>
            </li>
            <li>
              <a href="/works/online-laundry-order-management-system">
                Online Laundry – Order Management System
              </a>
            </li>
            <li>
              <a href="/works/logistic-solution-erp">Logistics Solutions ERP</a>
            </li>
            <li>
              <a href="/works/sports-ecosystem">Sports Ecosystem</a>
            </li>
            <li>
              <a href="/works/webion-virtual-online-shopping">
                Webion Virtual Online Shopping
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="footer-bottom">
        <p>@2023 Sublime Technocorp Pvt Ltd</p>
      </div>
    </footer>
  );
}
