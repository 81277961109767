import React, { useState } from "react";
import "../../components/work/WorkpageT1.scss";
import { BsFillCircleFill } from "react-icons/bs";
import Accordion from "react-bootstrap/Accordion";
import Work6 from "../../assets/img/works/workcover6.jpg";
import PurposeBg from "../../assets/img/works/WorkPurpose6.jpg";
import { SublimeClientData } from "../../components/work/WorkpageT1Data";
import SkillsCard from "../../components/abtus/SkillsCard";
import { ReactComponent as Line } from "../../assets/icons/gradient_line.svg";
import { ReactComponent as Line2 } from "../../assets/icons/gradient lineL.svg";
import { CurvedText } from "../../components/home/ScrollPercentage";
import { Helmet } from "react-helmet";
export default function SublimeClient() {
  const [selectedIndex, setSelectedIndex] = useState(9);
  const [showIcon, setShowIcon] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const handleContentClick = (index) => {
    // Toggle the expanded state
    if (index === selectedIndex) {
      setSelectedIndex(9);

      setExpanded(!expanded);

      return;
    }
    setTimeout(() => {
      setSelectedIndex(index);
    }, 100);
    if (!expanded) setExpanded(!expanded);
    // if(selectedIndex != index )
    // setExpanded(!expanded);
  };
  return (
    <>
      <div>
        <Helmet>
          <title>
            Sublime Client Management System | Sublime Technocorp Pvt Ltd
          </title>
        </Helmet>
        <div className="Sublime-client-page">
          <div className="work-page-t1-container">
            <img
              src={Work6}
              alt="Sublime Client"
              className="work-page-banner-image"
            />
            <div className="work-page-banner-title-text-overlay">
              <span>
                <Line2 className="line-svg" />
                <h1>Sublime Client Management System</h1>
                <Line className="line-svg" />
              </span>
            </div>
          </div>
          <div className="work-page-t1-heading">
            <h2>Web Based Client Management System</h2>
          </div>
          <div className="purpose-container">
            <img src={PurposeBg} alt="Purpose" className="purpose-image" />
            <div className="purpose-container-text-overlay">
              <h2>Purpose</h2>
              <span>
                We present an intuitive and user-friendly customer relationship
                management tool that simplifies your client management needs by
                organizing all their information in one space. It is not limited
                to just contact information, but you get the entire conversation
                history, deal information, and more with just a scroll.
              </span>
            </div>
          </div>
          <div className="work-page-t1-benefits">
            <div className="work-page-t1-benefits-title-div">
              <h2>Benefits</h2>
              <p>
                Bring the advantage of a thoughtful, secure, reliable, and
                sustainable system.
              </p>
            </div>
            <div className="work-page-t1-benefits-list-div">
              <ul type="none">
                <li>
                  <BsFillCircleFill size={15} className="circle-icon" />
                  Organized Information
                </li>
                <li>
                  <BsFillCircleFill size={15} className="circle-icon" />
                  Better collaboration
                </li>
                <li>
                  <BsFillCircleFill size={15} className="circle-icon" />
                  Higher revenue
                </li>
                <li>
                  <BsFillCircleFill size={15} className="circle-icon" />
                  Automated workflows
                </li>
                <li>
                  <BsFillCircleFill size={15} className="circle-icon" />
                  Easy & Efficient
                </li>
              </ul>
            </div>
          </div>
          <div className="work-page-features">
            <div className="work-page-feature-title-div">
              <h2>Features:</h2>
            </div>
            <div className="work-page-feature-content-div">
              {SublimeClientData.map((works, index) => (
                <SkillsCard
                  key={index}
                  index={index}
                  title={works.title}
                  subtitle={works.subtitle}
                  onClick={handleContentClick}
                  show={index === selectedIndex}
                />
              ))}
            </div>
          </div>
          <CurvedText />
        </div>
      </div>
    </>
  );
}
