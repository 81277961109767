import React from "react";
import "./careersCard.scss";
import Card from "react-bootstrap/Card";
export default function careersCard({ icon, careerTitle, careerSubheading }) {
  return (
    <>
      {/* <Card  className='careers-card' >
      <Card.Img variant="top" src={icon} className='careers-card-icon' />
      <Card.Body className='careers-card-body'>
        <div className='careers-card-content-div'>

        <Card.Title><h4 className='careers-card-title'>{careerTitle}</h4></Card.Title>
        <Card.Text>
            <span className='careers-card-subheading'>

          {careerSubheading}
            </span>
        </Card.Text>
  
        </div>
      </Card.Body>
    </Card> */}

      <div
        class="service-item-inner  over-hidden p-relative box-hover-image v-light background-transparent careers-card-div"
        data-id="f5ec285"
        data-element_type="widget"
        data-settings='{"_animation":"fadeInDown","_animation_tablet":"fadeIn","_animation_mobile":"fadeIn"}'
        data-widget_type="dsn_service.default"
      >
        <div class="box-img-shadow p-absolute"></div>
        <div class="dsn-icon mb-20 text-center ">
          <img src={icon} alt="icon-careers" width={50} />
        </div>
        <div class="service-content p-relative">
          <h4 class="service_title text-center ">{careerTitle}</h4>
          <p>{careerSubheading}</p>
        </div>
      </div>
    </>
  );
}
