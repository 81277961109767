import Work1 from "../../assets/img/works/workcover1.jpg";
import WorkPurpose1 from "../../assets/img/works/WorkPurpose1.jpg";
import Work2 from "../../assets/img/works/workcover2.png";
import WorkPurpose2 from "../../assets/img/works/WorkPurpose2.jpg";
import Work3 from "../../assets/img/works/workcover3.jpg";
import WorkPurpose3 from "../../assets/img/works/WorkPurpose3.jpg";
import Work4 from "../../assets/img/works/workcover4.jpg";
import Work5 from "../../assets/img/works/workcover5.jpg";
import WorkPurpose5 from "../../assets/img/works/WorkPurpose5.jpg";
import Work6 from "../../assets/img/works/workcover6.jpg";
import WorkPurpose6 from "../../assets/img/works/WorkPurpose6.jpg";
import Work7 from "../../assets/img/works/workcover7.jpg";
import WorkPurpose7 from "../../assets/img/works/WorkPurpose7.jpg";
import Work8 from "../../assets/img/works/workcover8.jpg";
import WorkPurpose8 from "../../assets/img/works/WorkPurpose8.jpg";
import Work9 from "../../assets/img/works/workcover9.jpg";
import WorkPurpose9 from "../../assets/img/works/WorkPurpose9.jpg";
import Work10 from "../../assets/img/works/workcover10.webp";
import WorkPurpose10 from "../../assets/img/works/WorkPurpose10.jpg";
import Work11 from "../../assets/img/works/workcover11.jpg";
import WorkPurpose11 from "../../assets/img/works/WorkPurpose11.jpg";
import Work12 from "../../assets/img/works/workcover12.jpg";
import WorkPurpose12 from "../../assets/img/works/WorkPurpose12.jpg";
import Work13 from "../../assets/img/works/workcover13.jpg";
import WorkPurpose13 from "../../assets/img/works/WorkPurpose13.jpg";
import Work14 from "../../assets/img/works/workcover14.jpg";
import WorkPurpose14 from "../../assets/img/works/WorkPurpose14.jpg";
import Work15 from "../../assets/img/works/workcover15.jpg";
import WorkPurpose15 from "../../assets/img/works/WorkPurpose8.jpg";
import Work16 from "../../assets/img/works/workcover16.jpg";
import WorkPurpose16 from "../../assets/img/works/WorkPurpose16.jpg";
import Work16Bg from "../../assets/img/works/Work16Bg.jpg";

export const WebionVirtualShopping = [
  {
    bgImg: Work1,
    title: "Webion Virtual Online Shopping",
    heading: "A web application for online shopping",
    boximg: WorkPurpose1,
    purposedescription:
      "To bring shopping a new and unique approach and enable people across locations to shop virtually. The virtual store platform helps brands and retailers create custom virtual shopping experiences that are integrated with e-commerce sites. This makes it easy for shoppers to find and purchase the items they want without having to leave the comfort of their online space.",
    benefitTitle: "Benefits",
    benefitsdescriptions:
      "Unique shopping experiences that offer customers a real-time experience of the products before purchasing, thus enhancing sales.",

    benefits: {
      benefit1: "Leading-edge technology",
      benefit2: "Proven performance and results",
      benefit3: "Generate more sales off of less square footage",
    },
    featureHeading: "Features",
    featuredescription:
      "Connect with people across the globe and convert them into your customer, from one location, one camera and one platform.",
    feature: [
      {
        title: "Live-streamed shopping",
        subtitle:
          "Binding buyer and seller in a live-streamed environment to enhance the personal shopping. Virtual reality tools are becoming increasingly popular in the fashion industry as they offer shoppers the opportunity to virtually try out pieces before making a purchase. This added confidence that the physical product will meet their expectations is proving to be a very useful marketing tool for fashion retailers.",
      },
      {
        title: "Generate Reports",
        subtitle:
          "Generate customized reports and charts to monitor sales and growth across various parameters.",
      },
      {
        title: "Vendor Management",
        subtitle:
          "The digital portfolio management tool allows you to make better decisions by giving you a unified view of your services, products, and vendors. The tool also lets you manage all vendor-related activities in one place, making it easier for you to keep track of everything.",
      },
    ],
  },
];

export const magnumfacility = [
  {
    bgImg: Work2,
    title: "Magnum Facility Management ERP",
    heading: "This is a software designed for the Facility Management ERP",
    boximg: WorkPurpose2,
    purposedescription:
      "This software is an automation of the vendor management and profitability tracking process. The app supports by incorporating scheduled tasks, service requests, and triggered interventions, which would be neatly prioritised and displayed. The app helps streamline the everyday chaos of vendor management and operations.",
    benefitTitle: "Benefits",
    benefitsdescriptions:
      "Built for the facility management industry, this software is automation of the entire process of sales, resourcing, clients, procurement, and report management. It efficiently uses your assets, provides quality maintenance, automates work order generation and allocation, and more—all while keeping costs low and your clients’ information secure.",
    benefits: {
      benefit1: "Work Optimization",
      benefit2: "Enhanced Productivity",
      benefit3: "Preventive Maintenance",
      benefit4: "Reduced Downtime",
      benefit5: "Streamlined order management",
    },
    featureHeading: "Features",
    feature: [
      {
        title: "Process Automation",
        subtitle:
          "Process Automation ERP is built for scalable systems and manpower management at different facility sites managed by the company. The clients can book in real-time with user-friendly touchpoints. Generate, assess, and prioritize work orders with our intelligent scheduling software",
      },
      {
        title: "Resource Management",
        subtitle:
          "Manage your resources via the backend portal, and plan their onboarding via HR processes, attendance management, salary calculation, and slip generation. Make sure you assign the right resources based on skills and availability – this will help things run more smoothly. Facility managers and technicians can stay up-to-date with regular status updates in the form of push notifications and mail escalations. This will help everyone stay on the same page and avoid any miscommunication.",
      },
      {
        title: "Client Management",
        subtitle:
          "Manage the deadlines and deliverables to the client along with proposal, salary, and invoice generation for the employees onboarded at the client’s place. Facility managers can now address urgent matters and access important files on both desktops and smartphones. Get a complete overview of your job history. Keep track of the status of your complaints easily",
      },
      {
        title: "Real-time Reports",
        subtitle:
          "Generate versatile real-time reports to get a bigger picture of the statistics of the company’s growth and monitoring the profit graph. Monitor performance and SLAs with real-time and historical dashboards. Get push notifications and email escalations to timely complete your work orders. Track work orders on a monthly, quarterly, half-yearly or yearly basis. This helps the facility managers in evaluating the areas that need greater focus and attention.",
      },
    ],
  },
];

export const digitalNgo = [
  {
    bgImg: Work3,
    title: "Digital NGO Platform",
    heading:
      "Aid bees is a web application with an automated ecommerce platform.",
    boximg: WorkPurpose3,
    purposedescription:
      "The Aidbees app is a complete social media platform. It is created for NGOs to share, follow, and create your profile which would in turn help you generate donations, and also attract and involve people to volunteer for the causes. This platform is also helps ease out the process of onboarding NGOs with full authentication. Additionally, it offers a readymade fully automated ecommerce platform for selling the goods prepared by these NGOs.",
    benefitTitle: "Benefits of Digital NGO Platforms Development",
    benefitsdescriptions:
      "This nexus helps in bringing different NGOs serving different causes all at one platform, so donors can choose the cause they way to support. All this is done with the right authentication and proof.",
    benefits: {
      benefit1: "Easy Donations",
      benefit2: "Easy Declarations",
      benefit3: "Donation Tracker",
      benefit4: "Build recurring donations",
      benefit5: "Gear your Campaigns",
    },
    featureHeading: "Features Of Digital NGO Platform Development",
    feature: [
      {
        title: "Donate",
        subtitle:
          "Find the right cause, an NGO, a campaign, with ease and donate your funds. Once donated you can come back and check the success story of your supported cause. One tap is all it takes to start generating a recurring stream of revenue. Donors can even set a date for donations to stop, or they can keep it going until further notice.",
      },
      {
        title: "Share your story",
        subtitle:
          "Inspire others by sharing your donation and kindness stories that created an impact. Images and videos are a fantastic way to tell your story and engage potential donors. You can use them right within the donation workflow to keep people updated on your progress. A progress tracker is also a great way to show real-time updates and keep people informed on your latest developments.",
      },
      {
        title: "Volunteer a cause",
        subtitle:
          "Support your donors through their generosity and help to make your dreams a reality. Thank your donors! Keep your supporters motivated by setting clear fundraising goals.",
      },
      {
        title: "Ecommerce Marketplace",
        subtitle:
          "A readymade ecommerce website to set-up your products for sale, and also to shop and gift your loved ones.Making your products available in more places will help you reach more customers. Give them plenty of options to choose from and make it easy for them to pay for your products. You can also help them discover your products by promoting them in new places.",
      },
    ],
  },
];
export const bridgitParenting = [
  {
    bgImg: Work4,
    title: "Bridgit Parenting & Schooling App",
    heading:
      "The Mobile app was built as a communication platform between school and parents.",
    boximg: WorkPurpose3,
    purposedescription:
      "Smart School Management Solution is an innovative solution designed to offer educators a complete package of features for school management while still providing parents with updates on their child's academic progress.",
    benefitTitle: "Benefits",
    benefitsdescriptions:
      "The simple app is aimed at faster and effective communication between the parents and school, seeing everything is communicated through one platform, and also documented.",
    benefits: {
      benefit1: "Customized Admin Web Portal",
      benefit2: "Bulk File Uploads",
      benefit3: "Student Performance Reports",
      benefit4: "Enhanced communication between stakeholders",
      benefit5: "Easy UI Interface and simple layouts",
    },
    featureHeading: "Features:",
    feature: [
      {
        title: "Communication",
        subtitle:
          "Bridgit is an easy-to-use app that enables better communication between parents and teachers and principal. Our school management solution includes a custom mobile application with a push notification feature that can send real-time notifications to parents.",
      },
      {
        title: "A platform to share",
        subtitle:
          "Bridgit serves as a platform to share documents, homework, tests, events, photos of activities, request appointments or ask questions.This applets you take control of your tasks, schedules, and notifications. The app also includes a messaging feature so you can stay in touch with parents.The announcements feature in the admin web portal allows you to send or schedule messages to parents and staff in bulk or individually. This makes the process of communication simple and efficient.",
      },
      {
        title: "Customized Dashboards",
        subtitle:
          "Through this app, the Principal and teachers can have dashboards that are customizable under different metrics and get a one-stop insight into various performance and deliverables. You can also access from here, communicate, get assignments, monitor grades, track their child’s progress, give MCQ class test and track attendance. This is a responsive communication app which helps to improve productivity by putting institution, staff, and parents on a common page, reducing paperwork and default communication, eliminating duplication of administrative effort.",
      },
      {
        title: "Visual Interface",
        subtitle:
          "The app offers the user experience a simple and typical interface like a social networking platform, with the focus to provide space to teachers and parents to share and communicate. The app anticipates user needs and deliver everything they want with just a few simple taps. Our customised app offers quick access to everything campus-related on-the-go for parents via push notifications.",
      },
      {
        title: "Easy Check-ins",
        subtitle:
          "Our easy-to-use Bridgit app allows for quick check-ins for parents allowing documentation uploads and viewing, pictures sharing and viewing, event updates and viewing, and taking appointments of school authorities, and more, giving the parents a chance to be part of their child’s school experiences.",
      },
      {
        title: "Multi-tier Access",
        subtitle:
          "The School Management solution offers three different levels of user access to the system. Super admin is for school administrators, admin access is for teachers & school staff and individual access is for parents. That way, everyone can have the appropriate level of access to the system according to their needs and responsibilities.",
      },
    ],
  },
];

export const profitabilityTracker = [
  {
    bgImg: Work5,
    title: "Profitability & Implementation Tracker",
    // heading: ""
    boximg: WorkPurpose5,
    purposedescription:
      "This is a web application custom-tailored for an ERP process of a lift manufacturing and maintenance company. It is built to measure project profitability analysis and to learn how to determine if your ongoing service is profitable.",
    benefitTitle: "Benefit",
    benefits: {
      benefit1: "Customer Relationship Management",
      benefit2: "Tracking Project Profitability",
      benefit3: "Data Driven Analysis",
      benefit4: "Automation and Streamlining Process",
    },
    featureHeading: "Features",
    feature: [
      {
        title: "Compliant Management",
        subtitle:
          "Keep track of customer support requests, customer satisfaction levels, and tickets that still need to be assigned – all from a single dashboard. Take your customer service to the next level and make it even easier for your team to manage customer support issues.",
      },
      {
        title: "Sales",
        subtitle:
          "Sales tracking software is a tool that salespeople can use to manage and track their sales process. It typically provides set process workflows so that salespeople know the sales valuation, where each prospect stands at any given point in time and what steps need to be taken next. By using a sales tracking software, salespeople can stay organized and guide their prospects through the sales process efficiently.",
      },
      {
        title: "Implementation",
        subtitle:
          "Processing customer documents and creating procedural flows for the implementation of the job. Distribution of task among the team and tracking their progress with the cost distribution task wise.",
      },
      {
        title: "Installation",
        subtitle:
          "Our sales management software is designed to help you streamline your sales process and sales representative management from start to finish. Our easy and fast installation process comes with guidelines and procedures to help your representatives track their progress and hit their targets.",
      },
      {
        title: "Service & Maintenance",
        subtitle:
          "Checks and alerts on periodic visits, follow ups, maintenance with AMC contracts and attractive reports on customer feedback and experience. Comprehensive and easy-to-use management reporting about business metrics. Sophisticated sales and profit analysis of the business.",
      },
    ],
  },
];

export const OnlineLaundaryData = [
  {
    bgImg: Work7,
    title: "Online Laundry – Order Management System",
    heading:
      "Manage your laundry management easily and faster through your mobile app.",
    boximg: WorkPurpose7,
    purposedescription:
      "A web and mobile app designed for highly interactive online laundry management through an easy-to-access dashboard",
    benefitTitle: "Benefits",
    benefits: {
      benefit1:
        "This Laundry Management Application will let you manage all your daily needs & activities in a smart, paperless, and efficient way. This can be used on a large scale for the hospitality management industry, hospitals and other bulk requirements for timely order and delivery.",
    },
    featureHeading: "Features",
    feature: [
      {
        title: "Book An Order",
        subtitle:
          "Customers can now book through their mobile app their laundry management by choosing the number of clothes, process, pickup date, time, and location.",
      },
      {
        title: "Easy Online Payment",
        subtitle:
          "With our payment system, you have a flexible way of making and receiving payments. You can easily pay or get paid via digital channels like cards, net banking, and UPI.",
      },
      {
        title: "Tagging",
        subtitle:
          "Our innovative tagging feature helps you keep track of your garments under process, so you can stop worrying about them getting misplaced.",
      },
      {
        title: "Tracking",
        subtitle:
          "Track your clothes as your order is being processed and stay updated on the delivery schedule.",
      },
    ],
  },
];
export const logisticSolution = [
  {
    bgImg: Work8,
    title: "Logistics Solutions ERP",
    heading:
      "Both software and app are created to automate and ease the logistics management process.",
    boximg: WorkPurpose8,
    purposedescription:
      "Through this app, you can streamline the last mile of your supply chain, delivery management, and automated delivery scheduling through real-time tracking alerts & updates.",
    benefitTitle: "Benefit",
    benefits: {
      benefit1:
        "You can now keep track of your in-house logistics management as well as CHA details through one application that can allow you to access, retrieve and analyze data all in one place. You can now plan, execute, and manage your logistics optimally from one source.",
    },
    featureHeading: "Features:",
    feature: [
      {
        title: "Internal processes",
        subtitle:
          "Automates the entire internal process of a logistic firm’s operations.",
      },
      {
        title: "Assign and record drivers to trucks",
        subtitle:
          "You can keep a record of all vehicles, and their drivers and assign vehicles and drivers for different consignments.",
      },
      {
        title: "Capture Client Requests",
        subtitle:
          "The application can take a specific client inquiry and all the necessary inputs required to process the order and simultaneously generate a quotation.",
      },
      {
        title: "Tracking on-field Operations",
        subtitle:
          "The application can take a specific client inquiry and all the necessary inputs required to process the order and simultaneously generate a quotation.",
      },
      {
        title: "Driver Consignment Updates",
        subtitle:
          "The mobile app can be used to feed all the necessary field activities, along with documents for CHA, assigned vendor to clear transportation, data collected from CHA and other field activities for effectively tracking the consignment.",
      },
      {
        title: "App for CHA",
        subtitle:
          "The CHAs can exclusively access the app and track orders that come to them, update the status of the orders, as well as upload the required documents.",
      },
    ],
  },
];
export const SportsEcosystemData = [
  {
    bgImg: Work9,
    title: "Sports Ecosystem",
    heading:
      "This is a website where sportsmen can create their sports profiles and compare their performances with others in the specialization.",
    boximg: WorkPurpose9,
    purposedescription:
      "Sports people can through this website create their detailed profiles and connect with people who have similar interests and goals and conquer new opportunities together.",
    benefitTitle: "Benefits",
    benefits: {
      benefit1: "Build an exhaustive sportsman profile in one place",
      benefit2:
        "Be identified by teams, game management councils and prospect offer",
      benefit3: " Update and maintain a profile on one medium",
      benefit4:
        "Showcase your performances, share/access the content, and stay connected for more work",
    },
    featureHeading: "Features :",
    feature: [
      {
        title: "Detailed Profile",
        subtitle:
          "Sportsman can list their championship, tournaments, events, international tours and all the other activities.",
      },
      {
        title: "Performance Statistics",
        subtitle:
          "Every match, every tournament, and performance records of every month, year’s scores can be listed in a detailed statistic of the sportsman’s experience.",
      },
      {
        title: "General Information",
        subtitle:
          "Athletes can also list general info, teams played with, sports specialization, events calendar, qualification, gallery of photos and videos, awards, sponsorships, and clubs played for.",
      },
      {
        title: "System Integration",
        subtitle:
          "Seamlessly integrate the app with your CRM with all inquiries being connected to Sales CRM.",
      },
      {
        title: "Savings Through Analysis",
        subtitle:
          "Create analytical reports of your consumption through the solar calculator with fields like tariff, average monthly unit consumption, and consumption load, to know the tariff of your consumption.",
      },
    ],
  },
];
export const ShippingManagementData = [
  {
    bgImg: Work10,
    title: "Shipping Management Solution",
    heading: "Web application for entire Shipping Management",
    boximg: WorkPurpose10,
    purposedescription:
      "You can now streamline your entire shipping management operation to generate greater efficiency and improved performance.",
    benefitTitle: "Benefit",
    benefits: {
      benefit1:
        "With this application, you can directly book your shipping consignment or make use of third-party vendors for your shipping management.",
    },
    featureHeading: "Features :",
    feature: [
      {
        title: "Book Shipment",
        subtitle:
          "Your clients can directly book shipments through the company app by filling out the shipping request. The client can do the procedure through this simple and automated system.",
      },
      {
        title: "Know Container Status",
        subtitle:
          "Before placing the order, you can have an estimate of the container space record, container space available, check space in multiple containers, assign chaises, and send a container request in case any further assistance is required.",
      },
      {
        title: "Third-party booking",
        subtitle:
          "Shipping companies can book containers for third parties by checking the stock and container status followed by generating the bill of lading – for overall containers, stock, and documentation.",
      },
      {
        title: "Report Generation",
        subtitle:
          "Generate different reports of your orders placed, cost estimates, and delivery schedules in simple steps.",
      },
      {
        title: "End-to-end Shipping Management",
        subtitle:
          "Our application is a comprehensive solution that covers every stage of your supply chain, from order scheduling and delivery planning to fleet utilization, resource optimization and final mile fulfilment.",
      },
    ],
  },
];
export const FarmersConnectData = [
  {
    bgImg: Work11,
    title: "Farmers Connect Application",
    boximg: WorkPurpose11,
    purposedescription:
      "The App is an online marketplace for farmers to promote and sell their products and services.",
    benefitTitle: "Benefits",
    benefits: {
      benefit1: "Farmers can connect with local consumers directly",
      benefit2:
        "Sell products directly without giving commissions to a middleman",
      benefit3:
        "Have continuous orders from memberships and dedicated customers",
      benefit4: "Transact online for better management through technology",
      benefit5: "Collaborate through other vendors and offer wider solutions",
    },
    featureHeading: "Features :",
    feature: [
      {
        title: "Web Portal",
        subtitle:
          "A portal where farmers can connect with local consumers directly, making it easy to sell vegetables, meat, fish, seeds, and more.",
      },
      {
        title: "Technology",
        subtitle:
          "An easy-to-use portal that has everything farmers need to sell their products and services easily and efficiently.",
      },
      {
        title: "Logistics Partners",
        subtitle:
          "A ready network of logistics partners that can be booked online for deliveries from farmer to consumer.",
      },
      {
        title: "Advertise products",
        subtitle:
          "Farmers can post ads promoting their various supplies and efficient pricing as compared to market prices.",
      },
      {
        title: "Membership for buyers",
        subtitle:
          "Buyers can take membership and be loyal customers to the portal and enjoy fresh and economical supplies of vegetables and natural produce.",
      },
      {
        title: "Direct B2b exchange of contacts",
        subtitle:
          "The consumers or buyers can directly contact the sellers and there is no middle person to be worried about.",
      },
      {
        title: "Better Options",
        subtitle:
          "As there is no middleman, buyers can get the economic products, freshest stocks, and faster deliveries.",
      },
    ],
  },
];

export const materialAutomationData = [
  {
    bgImg: Work12,
    title: "Material Testing & Lab Automation",
    heading:
      "A highly customised and revolutionary material testing app for the construction industry.",
    boximg: WorkPurpose12,
    purposedescription:
      "Automation of materials and their quality checks for construction industries.",
    benefitTitle: "Benefits",
    benefits: {
      benefit1: "Complete lab automation",
      benefit2:
        "Extensive tests as per different norms and client requirements",
      benefit3: "In-house and on-field testing options",
      benefit4: "Automation of processes",
      benefit5: "Faster, easier, and timesaving solutions",
    },
    featureHeading: "Features :",
    feature: [
      {
        title: "Material Testing",
        subtitle:
          "Test as many as 12 materials and 250 different tests through this solitary app.",
      },
      {
        title: "Set Indicators",
        subtitle:
          "You can assign different sample tests, put relevant data inputs, perform the test, collect test results, and generate an invoice for the set of tests performed.",
      },
      {
        title: "Field Tests",
        subtitle:
          "Certain tests can be exclusively performed on the field and can generate easy and quick results ensuring timesaving.",
      },
      {
        title: "ERP",
        subtitle:
          "This ERP can be integrated into your system to generate different reports and perform analysis.",
      },
      {
        title: "Automation",
        subtitle:
          "Complete lab automation ensures all the processes are precisely calibrated and calculated and can be repeated for error-free results.",
      },
    ],
  },
];
export const onlineBiddingData = [
  {
    bgImg: Work13,
    title: "Online Bidding Facilitating",
    heading: "Web application to display products and bid online",
    boximg: WorkPurpose13,
    purposedescription:
      "Online auction site to create an additional platform for online bidders.",
    benefitTitle: "Benefits",
    benefits: {
      benefit1: "Increase your auction participation",
      benefit2:
        "You can import your guest list to send out the invitations for bidding",
      benefit3:
        "Bid on your favourite things from the comfort and security of your web application",
    },
    featureHeading: "Features : ",
    feature: [
      {
        title: "Upload advertisements",
        subtitle:
          "Bidders can create ads for their product bidding and upload them on the platform.",
      },
      {
        title: "Normal Ads",
        subtitle:
          "This is not a time-specific ad. You can put up an ad, and interested customers can like, bid, and contact for closing the deal offline.",
      },
      {
        title: "Scheduled Ads",
        subtitle:
          "These are ads scheduled for a certain timeframe. The ad responses are integrated with zoom. Whenever a bidder is intere",
      },
      {
        title: "Live ads",
        subtitle:
          "A bidder can put up instant LIVE ads and the response is directed to a zoom meeting. All the online customer gets communication about the LIVE ads and bidding, and they can then participate and bid as well as know who else is bidding and at what price.",
      },
      {
        title: "Customised Product",
        subtitle:
          "The application is customised to a certain bidding requirement and can be altered as per other specifics.",
      },
    ],
  },
];

export const solarWebAppData = [
  {
    bgImg: Work14,
    title: "Solar Web Application",
    heading:
      "A Solar web application that proactively guides consumers in choosing the right solution before buying.",
    boximg: WorkPurpose14,
    purposedescription:
      "This Web App is a perfect solution for anyone looking for online management, information, access and installation of solar services for an informed investment.",
    benefitTitle: "Benefits",
    benefits: {
      benefit1: "Make informed decisions and investment",
      benefit2: "Know about your consumption and tariff",
      benefit3: "Identify what solution best fits your requirement",
      benefit4: "Customise as per your requirement",
    },
    featureHeading: "Features :",
    feature: [
      {
        title: "Customised Solar Solutions",
        subtitle:
          "Choose a solar system based on your need – commercial, industrial, or residential by offering inputs like tariff, average monthly consumption and calculate the cost, scale of solar panel to be implemented and related cost savings.",
      },
      {
        title: "Know your requirement",
        subtitle:
          "Identify beforehand what solar panels you are opting for, and how much electricity it will save and based on that opt for the right solution.",
      },
      {
        title: "Portfolio of Solar Panels",
        subtitle:
          "Our application can list the complete portfolio of solar panels that can be delivered worldwide.",
      },
      {
        title: "System Integration",
        subtitle:
          "Seamlessly integrate the app with your CRM with all inquiries being connected to Sales CRM.",
      },
      {
        title: "Savings Through Analysis",
        subtitle:
          "Create analytical reports of your consumption through the solar calculator with fields like tariff, average monthly unit consumption, and consumption load, to know the tariff of your consumption.",
      },
    ],
  },
];

export const AIbasedHealthData = [
  {
    bgImg: Work15,
    title: "AI Based Health Monitoring",
    heading:
      "Heliom AI Based Health Monitoring System Development & Consultation",
    subheading:
      "Mobile app and web-application for online appointment, consultation and pre health check using advanced AI based health monitoring.",
    boximg: WorkPurpose15,
    purposedescription:
      "The patient's healthcare mobile apps provide services like managing health and patient monitoring. It also allows healthcare providers to streamline clinical communication, improve workflows, and manage connect between patients, doctors, and healthcare providers. By using this app patients can take a more active role in their own health and wellness, and healthcare providers can focus on delivering quality care.",
    benefitTitle: "Benefits of AI based health monitoring system development",
    benefits: {
      benefit1: "Improved Patient Engagement",
      benefit2: "Immediate Access to Healthcare",
      benefit3: "Easy Online Payments",
      benefit4: "Improved Prescription",
      benefit5: "Easy Consultation Booking and Scheduling",
      benefit6: "Affordable and Easily Accessible",
    },
    featureHeading:
      "Features of AI based health monitoring system development:",
    feature: [
      {
        title: "AI enabled Health Points",
        subtitle:
          "The app through the mobile camera is able to gauge oxygen blood pressure and other parameters. Patient-monitored data, such as blood pressure, HRV, oxygen level, heart rate, stress level and respiration rate, can be extremely valuable to insurance companies and doctors. By allowing patients to monitor this data at home and feeding it back to their insurance or doctor, we can provide them with a much more comprehensive picture of their health.",
      },
      {
        title: "Affordable & accessible",
        subtitle:
          "Ensuring health and wellness always with you though the app. A telemedicine app can provide health plans to the individual for an affordable price. This way, you can have health and wellness with you at all times!",
      },
      {
        title: "Health Analytics",
        subtitle:
          "Through an interactive UI dashboard have a tab on health charts, appointments, visits, and insurance of an individual's health. By monitoring healthcare KPIs in a dynamic and interactive way, healthcare professionals are able to access important patient statistics in real-time. This increases overall hospital performance and patient satisfaction.",
      },
      {
        title: "Predictive Symptoms Analysis",
        subtitle:
          "Our AI system has collected data that provides patients and doctors with the possible symptoms. This allows patients to have a better understanding of their condition and doctors to be more efficient in diagnosing and treating patients.",
      },
      {
        title: "Integrated Health Insurance",
        subtitle:
          "This app can provide comprehensive information about which facility can be opted in which condition. The app is also designed to help you understand your health insurance needs so that you can make the best decision for your family. Through this app you can claim your insurance directly post verification of health stats and doctor consultation.",
      },
      {
        title: "Virtual Appointment",
        subtitle:
          "Easy-to-use Zoom integration and customization features allow you to consult with patients quickly and easily, along with your care team. What makes this medical app unique is that it offers users the convenience of scheduling both on-demand house calls with doctors and virtual appointments, which supports patient engagement and practice management. This app is perfect for users who want the convenience of a doctor’s house call without having to leave the comfort of their own homes.",
      },
    ],
  },
];

export const UndergroundMinesData = [
  {
    bgImg: Work16,
    title: "Under Ground Mines ERP",
    heading:
      "Under Ground Mines ERP – is a multipurpose system for enhancing industrial security in underground mining.",
    boximg: WorkPurpose16,
    purposedescription:
      "A custom-tailored solution to plan, monitor and control the movement in the underground mines thousand feet below the ground with a centralised system.",
    benefitBg: Work16Bg,
    benefitTitle: "Benefits",
    benefits: {
      benefit1: "Enhanced communication",
      benefit2: "Remote controls",
      benefit3: "Resource management",
      benefit4: "Sharp tracking systems",
    },
    featureHeading: "The Web application has following Features:",
    feature: [
      {
        title: "Personnel and Equipment Tracking",
        subtitle:
          "One of the major concerns while working underground in mines is the safety of workers and the proper utilisation of resources which can be easily managed by our integrated system and dashboard. Our app enables tracking, monitoring and management of people and equipment in mine operations.",
      },
      {
        title: "Asset Management",
        subtitle:
          "Increase productivity and reduce operational costs with our advanced asset management system. Followed by predictive maintenance, improving machine availability, reducing ongoing maintenance costs, and identifying failure points.",
      },
      {
        title: "Sensors & Telematics",
        subtitle:
          "Passive RFID systems decked with smart IOT technology to monitor and fetch data to pool in the statistics required for the analysis and dashboards. Improved data collection and analysis via sensors and the internet will enable mining companies to operate mines more safely, as well as increase productivity and reduce costs. By collecting data on everything from the environment to the machinery, mining companies can get a better picture of what’s happening underground and make decisions accordingly. Additionally, this data can be used to improve efficiency and lower costs by reducing downtime and maximizing resources.",
      },
      {
        title: "Wireless Communication",
        subtitle:
          "Wireless mining communication networks play a vital role in mine operations by transporting data, voice, and video signal – supporting applications that are essential to efficient and safe mine conditions. Wireless communications can significantly reduce downtime for issues like equipment failure or worker injury by allowing for quick and reliable high wall scans and field data that can be sent directly to the mine management system.",
      },
      {
        title: "Centralised Control System",
        subtitle:
          "Real-time collaboration between mining sites, processing plants and remote-operations control centers enables informed decision-making and strategic planning. This allows for a more efficient and coordinated workflow between all parties with different roles and responsibilities in a hierarchy involved in the mining process, reducing downtime, and ultimately increasing profits.",
      },
    ],
  },
];

export const SublimeClientData = [
  
    // feature: [
      {
        title: "Logins",
        subtitle:
          "The application offers various logins for security purpose. Admin, employees, managers, and clients can login separately for data privacy and security restrictions.",
      },
      {
        title: "Database Management",
        subtitle:
          "Your information like database of clients, industries, employee details and much more should always be up-to-date and easily accessible. Keeping everything in sync across your organization will help align your teams around a single source of data. Real-time data from different sources can help you make informed decisions quickly and efficiently.",
      },
      {
        title: "Services Portfolio",
        subtitle:
          "Track, manage, and analyse the services within their portfolios. Gain insights into how well each service is performing and how it contributes to the overall portfolio. Identify opportunities for improvement and make decisions about which services to keep, discontinue, or invest in.",
      },
      {
        title: "Task Assignment",
        subtitle:
          "Assign tasks to your employees to notify them about what’s needed and by when to reduce unnecessary meetings and interactions.",
      },
      {
        title: "Task Execution",
        subtitle:
          "Engage in real-time discussions at various stages of work progress all accessible on our mobile apps to speed up discussions and feedback gathering.",
      },
      {
        title: "Document Uploads",
        subtitle:
          "Upload, store, and organize files at one place. Collaborate and share feedback on files in real time.",
      },
      {
        title: "Value Addition",
        subtitle:
          "A chat feature to support internal teams as well as peer-to-peer communication with clients.",
      },
    // ],
  
];
