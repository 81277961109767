import React from "react";
import { AIbasedHealthData } from "../../components/work/WorkpageT1Data";
import WorkpageT1 from "../../components/work/WorkpageT1";
export default function AIbasedHealth() {
  return (
    <>
      {AIbasedHealthData.map((work15) => (
        <WorkpageT1
          bgImg={work15.bgImg}
          title={work15.title}
          heading={work15.heading}
          subheading={work15.subheading}
          boximg={work15.boximg}
          purposedescription={work15.purposedescription}
          benefitTitle={work15.benefitTitle}
          benefits={work15.benefits}
          featureHeading={work15.featureHeading}
          featuredescription={work15.featuredescription}
          feature={work15.feature}
        />
      ))}
    </>
  );
}
