import React from "react";
import { digitalNgo } from "../../components/work/WorkpageT1Data";
import WorkpageT1 from "../../components/work/WorkpageT1";

export default function DigitalNgo() {
  return (
    <>
      {digitalNgo.map((work3) => (
        <WorkpageT1
          bgImg={work3.bgImg}
          title={work3.title}
          heading={work3.heading}
          purposedescription={work3.purposedescription}
          benefitTitle={work3.benefitTitle}
          benefitsdescriptions={work3.benefitsdescriptions}
          benefits={work3.benefits}
          boximg={work3.boximg}
          featureHeading={work3.featureHeading}
          featuredescription={work3.featuredescription}
          feature={work3.feature}
        />
      ))}
    </>
  );
}
