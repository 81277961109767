export const skillsData = [
    {
        title: "Backend",
        subtitle: "Laravel, CodeIgniter, Yii, Django, Nodejs"
    },
    {
        title: "Frontend",
        subtitle: "HTML5, CSS3, Angular, Bootstrap, JavaScript, <br> ReactJS, Vuejs, ExpressJS, THREEjs"
    },
    {
        title: "Mobile",
        subtitle: "Java, Kotlin, Swift, Flutter, React native"
    },
    {
        title: "Data Science",
        subtitle: "Python, Panda, TensorFlow, Tableau, Power BI"
    },
    {
        title: "Database",
        subtitle: "MySQL, MS SQL, MongoDB, Oracle, NoSQL, Bigdata"
    },
    {
        title: "Microsoft .net framework",
        subtitle: "C#, Asp.Net, MVC, CORE"
    },
  
]
