import React from "react";
import { magnumfacility } from "../../components/work/WorkpageT1Data.js";
import WorkpageT1 from "../../components/work/WorkpageT1.jsx";

export default function MagnumFacilityERP() {
  return (
    <>
      {magnumfacility.map((work2) => (
        <WorkpageT1
          bgImg={work2.bgImg}
          title={work2.title}
          heading={work2.heading}
          purposedescription={work2.purposedescription}
          benefitTitle={work2.benefitTitle}
          benefitsdescriptions={work2.benefitsdescriptions}
          benefits={work2.benefits}  
          boximg={work2.boximg}
          featureHeading={work2.featureHeading}
          featuredescription={work2.featuredescription}
          feature={work2.feature}
        />
      ))}
    </>
  );
}
