import React from "react";
import "./styles.scss";


export default function ProgressBar({ value }) {
  return (
    <div>
      <div className="progress">
        <div className="currentProgress" style={{ width: `${value}%` }} />
      </div>
      
    </div>
  );
}
