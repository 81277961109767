import React, { useState, useRef } from "react";
import "./Home.scss";
import { Helmet } from "react-helmet";
import SublimeVideo from "../../assets/img/Y2Mate.mp4";
import WelcomeImg from "../../assets/img/wel.jpg";
import { ServicesCardData } from "../../components/home/ServicesCardData";
import ServicesCard, {
  TempServicesCard,
} from "../../components/home/ServicesCard";
import { BsRecordCircleFill } from "react-icons/bs";
import { IoChevronForwardCircleSharp } from "react-icons/io5";
import { BsCheckLg } from "react-icons/bs";
import { ReactComponent as Line } from "../../assets/icons/gradient_line.svg";
import { ReactComponent as Line2 } from "../../assets/icons/gradient lineL.svg";
import "../../../node_modules/swiper/swiper.scss";
import Carousel3D from "../../components/home/Carousel3D";
import { CurvedText } from "../../components/home/ScrollPercentage";
import Partners from "../../components/home/Partners";
import { useInView } from "react-intersection-observer";

export default function Home() {
  // const ref = useRef(null)
  //   const isInView = useInView(ref)
  // const ref = useRef();
  // const [inView, setInView] = useInView();
  const [ref1, inView1] = useInView({
    triggerOnce: true, // Ensures the animation only plays once
    threshold: 0.5, // Adjust the visibility threshold as needed
  });
  const [ref, inView] = useInView({
    triggerOnce: true, // Ensures the animation only plays once
    threshold: 0.5, // Adjust the visibility threshold as needed
  });
  const [ref2, inView2] = useInView({
    triggerOnce: true, // Ensures the animation only plays once
    threshold: 0.5, // Adjust the visibility threshold as needed
  });
  const [ref3, inView3] = useInView({
    triggerOnce: true, // Ensures the animation only plays once
    threshold: 0.5, // Adjust the visibility threshold as needed
  });
  const [ref4, inView4] = useInView({
    triggerOnce: true, // Ensures the animation only plays once
    threshold: 0.5, // Adjust the visibility threshold as needed
  });

  return (
    <>
      <Helmet>
        <title>Sublime Technocorp Pvt Ltd – Software Development Company | Sublime Technocorp Pvt Ltd</title>
      </Helmet>
      <div className="home-page">
        <div className="home-container">
          <div className="home-video">
            <video autoPlay loop muted className="video-element">
              <source src={SublimeVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <div className="text-overlay">
              <h1>Sublime Technocorp Pvt Ltd</h1>
              <h2>WE ARE YOUR ALLIES IN LEADING TECHNOLOGICAL FORAYS</h2>
            </div>
          </div>
          <div className="welcome-container">
            <div className="welcome-content-container">
              <div className="welcome-text">
                <h5 style={{ color: "#696969" }}>
                  WE AT, SUBLIME TECHNOCORP PVT LTD
                </h5>
                <Line />
              </div>
              <h2 className="welcome-title">
                Building Your Future Organisations Through Technology And
                Automation Aids
              </h2>
              <div className="welcome-content">
                We are a Technology company, and work passionately towards
                creating solutions of tomorrow. We are driven by the purpose of
                making work easier for our clients though streamlining,
                automation and other efficient technological contributions. We
                aspire to work on assignments and challenges that have not been
                addressed before and develop effective, timely and economic
                solution.
              </div>
            </div>
            <div className="welcome-image">
              <img src={WelcomeImg} alt="Welcome" className="welcomeimg" />
            </div>
          </div>
          <div className="how-we-work-container">
            <div ref={ref1} className={inView1 ? "how-we-work-title" : ""}>
              <h3>How do we do it?</h3>
            </div>
            <div ref={ref1} className={inView1 ? "how-we-work-content" : ""}>
              <p>
                We develop global custom software development services for
                start-ups, SMEs MSMEs, and Corporates businesses.
                <br /> We work on an agile system, an end-to-end product
                lifecycle management model that encompasses conceptualization,
                front-end and back-end coding, deployment, and QA.
              </p>
            </div>
          </div>
          <div className="what-we-provide-container">
            {/* <div className="line-shape"> */}

            {/* </div> */}
            <h2 className="services-watermark">SERVICES</h2>
            <span>
              <Line2 style={{ marginBottom: "15px" }} />
              <p className="section-title">WHAT WE PROVIDE</p>
              <Line style={{ marginBottom: "15px" }} />
            </span>
            <div className="title-block-lg">
              <h2>OUR SERVICES</h2>
            </div>
          </div>
          <div className="services-container">
            <div className="services-div">
              <div ref={ref4} className={inView4 ? "services" : ""}>
                <TempServicesCard />
              </div>
            </div>
          </div>
          <div className="we-ensure-container">
            <div ref={ref2} className={inView2 ? "we-ensure-title" : ""}>
              <span>We ensure</span>
            </div>
            <div ref={ref2} className={inView2 ? "we-ensure-list" : ""}>
              <ul type="none" className="we-ensure-list-ul">
                <li className="we-ensure-list-li">
                  <BsRecordCircleFill />
                  Real-time project mapping and deliveries
                </li>
                <li className="we-ensure-list-li">
                  <BsRecordCircleFill />
                  Developing scalable solutions
                </li>
                <li className="we-ensure-list-li">
                  <BsRecordCircleFill />
                  System integration & support
                </li>
              </ul>
            </div>
          </div>
          <div className="working-on-what-matters-containers">
            <div className="matters-content">
              <div ref={ref} className={inView ? "matters-title" : ""}>
                <h4>Working on what matters</h4>
                <p>
                  We build digital experiences and brands that people fall in
                  love with. When I hear the buzz of the little world among the
                  stalks, and grow familiar with the countless indescribable.
                </p>
              </div>
            </div>
            <div ref={ref} className={inView ? "matters-list" : ""}>
              <span type="none">
                <li className="matters-list-li">
                  <div className="matters-list-li-icon">
                    <IoChevronForwardCircleSharp size={40} fill="#000" />
                  </div>
                  <div>
                    <div className="matters-list-title">Backend</div>
                    <p className="matters-list-subtitle">
                      Laravel, CodeIgniter, Yii, Django, Nodejs
                    </p>
                  </div>
                </li>
                <li className="matters-list-li">
                  <div className="matters-list-li-icon">
                    <IoChevronForwardCircleSharp size={40} fill="#000" />
                  </div>
                  <div>
                    <div className="matters-list-title">Frontend</div>
                    <p className="matters-list-subtitle">
                      HTML5, CSS3, Angular, Bootstrap, JavaScript, ReactJS,
                      Vuejs, ExpressJS, THREEjs
                    </p>
                  </div>
                </li>
                <li className="matters-list-li">
                  <div className="matters-list-li-icon">
                    <IoChevronForwardCircleSharp size={40} fill="#000" />
                  </div>
                  <div>
                    <div className="matters-list-title">Mobile</div>
                    <p className="matters-list-subtitle">
                      Java, Kotlin, Swift, Flutter, React native
                    </p>
                  </div>
                </li>
                <li className="matters-list-li">
                  <div className="matters-list-li-icon">
                    <IoChevronForwardCircleSharp size={40} fill="#000" />
                  </div>
                  <div>
                    <div className="matters-list-title">Data Science</div>
                    <p className="matters-list-subtitle">
                      Python, Panda, TensorFlow, Tableau, Power BI
                    </p>
                  </div>
                </li>
                <li className="matters-list-li">
                  <div className="matters-list-li-icon">
                    <IoChevronForwardCircleSharp size={40} fill="#000" />
                  </div>
                  <div>
                    <div className="matters-list-title">Database</div>
                    <p className="matters-list-subtitle">
                      MySQL, MS SQL, MongoDB, Oracle, NoSQL, Bigdata
                    </p>
                  </div>
                </li>
                <li className="matters-list-li">
                  <div className="matters-list-li-icon">
                    <IoChevronForwardCircleSharp size={40} fill="#000" />
                  </div>
                  <div>
                    <div className="matters-list-title">
                      Microsoft .net framework
                    </div>
                    <p className="matters-list-subtitle">
                      C#, Asp.Net, MVC, CORE
                    </p>
                  </div>
                </li>
              </span>
            </div>
          </div>
          <div className="why-us-container">
            <div
              style={{ marginTop: "200px" }}
              ref={ref3}
              className={inView3 ? "why-us-box" : ""}
            >
              <div className="why-us-div">
                <div className="why-us-content">
                  <h2
                    style={{
                      fontSize: "44px",
                      fontWeight: "500",
                      letterSpacing: "2px",
                    }}
                  >
                    WHY US ?
                  </h2>
                  <p>
                    We deliver end-to-end software development services across
                    various industries developing solutions that are dynamic,
                    comprehensive, and designed around subject-matter expertise.
                  </p>
                  <div>
                    <div className="why-us-points">
                      <li className="why-us-li">
                        <span>
                          <BsCheckLg size={25} />
                        </span>
                        We have developed pioneering solutions for clients
                        across their unique needs
                      </li>
                      <hr />
                      <li className="why-us-li">
                        <span>
                          <BsCheckLg size={25} />
                        </span>
                        We are at the forefront of technology and adapt the
                        latest
                      </li>
                      <hr />
                      <li className="why-us-li">
                        <span>
                          <BsCheckLg size={25} />
                        </span>
                        We are the trusted technology and resource partners for
                        number of companies
                      </li>
                      <hr />
                      <li className="why-us-li">
                        <span>
                          <BsCheckLg size={25} />
                        </span>
                        Delivering on time and within budget
                      </li>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="our-works-container">
            <div className="our-works-title">
              <h1>WORKS</h1>
              <span className="our-works-title-span">
                <Line2 style={{ marginBottom: "15px" }} />
                <p>FIND WHAT FACINATES YOU</p>
                <Line style={{ marginBottom: "15px" }} />
              </span>
              <h2>OUR WORKS</h2>
            </div>

            <div className="carousel-container">
              <Carousel3D />
            </div>

            <Partners />
          </div>
        </div>
        <div className="get-in-touch">
          <span className="get-in-touch-span">
            <h2>GET IN TOUCH</h2>
          </span>
          <h1>CONNECT</h1>
          <span className="get-in-touch-div">
            <Line2 style={{ marginBottom: "15px" }} />
            <p>
              WE WILL HELP YOU TO ACHIEVE YOUR GOALS AND TO GROW YOUR BUSINESS.
            </p>
            <Line style={{ marginBottom: "15px" }} />
          </span>
        </div>
      </div>
      <CurvedText />
    </>
  );
}
