import React from "react";
import { ShippingManagementData } from "../../components/work/WorkpageT1Data";
import WorkpageT1 from "../../components/work/WorkpageT1";
export default function ShippingManagement() {
  return (
    <>
      {ShippingManagementData.map((work10) => (
        <WorkpageT1
          title={work10.title}
          bgImg={work10.bgImg}
          heading={work10.heading}
          boximg={work10.boximg}
          purposedescription={work10.purposedescription}
          benefitTitle={work10.benefitTitle}
          benefitsdescriptions={work10.benefitsdescriptions}
          benefits={work10.benefits}
          featureHeading={work10.featureHeading}
          featuredescription={work10.featuredescription}
          feature={work10.feature}
        />
      ))}
    </>
  );
}
