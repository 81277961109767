import "bootstrap/dist/css/bootstrap.min.css";
import { Splash } from "./pages/splash/splash";
import { Helmet } from "react-helmet";
import "./App.scss";
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import CloudTransformation from "./pages/services/CloudTransformation.jsx";
import DedicatedDevelopers from "./pages/services/DedicatedDevelopers.jsx";
import CustomSoftware from "./pages/services/CustomSoftware.jsx";
import ProductDevelopment from "./pages/services/ProductDevelopment.jsx";
import DataAnalytics from "./pages/services/DataAnalytics.jsx";
import WebionVirtualOnlineShopping from "./pages/works/WebionVirtualOnlineShopping.jsx";
import MagnumFacilityERP from "./pages/works/MagnumFacilityERP.jsx";
import DigitalNgo from "./pages/works/DigitalNgo";
import BridgitParenting from "./pages/works/BridgitParenting";
import Profitability from "./pages/works/ProfitabilityImplementation";
import SublimeClient from "./pages/works/SublimeClient";
import OnlineLaundary from "./pages/works/OnlineLaundary.jsx";
import UndergroundMines from "./pages/works/UndergroundMines.jsx";
import AIbasedHealth from "./pages/works/AIbasedHealth.jsx";
import SolarWebApp from "./pages/works/SolarWebApp.jsx";
import OnlineBidding from "./pages/works/OnlineBidding.jsx";
import MaterialTesting from "./pages/works/MaterialTesting.jsx";
import FarmersConnectApp from "./pages/works/FarmersConnectApp.jsx";
import ShippingManagement from "./pages/works/ShippingManagement.jsx";
import SportsEcosystem from "./pages/works/SportsEcosystem.jsx";
import LogisticSol from "./pages/works/LogisticSol.jsx";
import AboutUs from "./pages/aboutus/AboutUs.jsx";
import Works from "./pages/works/Works.jsx";
import Careers from "./pages/careers/Careers";
import ContactUs from "./pages/contactus/ContactUs";
import PrivacyPolicy from "./pages/privacypolicy/PrivacyPolicy";
import Navbar from "./components/navbar/Navbar";
import FooterPage from "./components/footer/Footer";
import Home from "./pages/home/Home";
import ScrollPercentage from "./components/home/ScrollPercentage";
import { CurvedText } from "./components/home/ScrollPercentage";
function App() {
  const [scrollPercentage, setScrollPercentage] = useState(0);
  useEffect(() => {
    const handleScroll = () => {
      const scrollHeight =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight;
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const scrollPercentage = Math.round((scrollTop / scrollHeight) * 100);
      setScrollPercentage(scrollPercentage);
    };
    window.addEventListener("scroll", handleScroll);
    handleScroll();

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <>
      {/* <div
        className="scroll-counter"
        style={{
          position: "sticky",
          bottom: 1,
          left: 0,
          width: "100%",
          textAlign: "center",
          backgroundColor: "#eee",
          padding: "10px",
        }}
      >
        Scroll Top: {scrollPercentage}%
      </div> */}

      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <Splash>
                <Navbar />
                <Home />
                <FooterPage />
                {/* <CurvedText />  */}
                {/* <ScrollPercentage /> */}
              </Splash>
            }
          />

          <Route
            path="/about-us"
            element={
              <Splash>
                <Navbar />
                <AboutUs />
                <FooterPage />
              </Splash>
            }
          />

          <Route
            path="/services/cloud-and-digital-transformation-service"
            element={
              <Splash>
                <Navbar />
                <CloudTransformation />
                <FooterPage />
              </Splash>
            }
          />
          <Route
            path="/services/dedicated-developers"
            element={
              <Splash>
                <Navbar />
                <DedicatedDevelopers />
                <FooterPage />
              </Splash>
            }
          />
          <Route
            path="/services/custom-software-development"
            element={
              <Splash>
                <Navbar />
                <CustomSoftware />
                <FooterPage />
              </Splash>
            }
          />
          <Route
            path="/services/product-development-services"
            element={
              <Splash>
                <Navbar />
                <ProductDevelopment />
                <FooterPage />
              </Splash>
            }
          />
          <Route
            path="/services/data-analytics-services"
            element={
              <Splash>
                <Navbar /> <DataAnalytics /> <FooterPage />
              </Splash>
            }
          />
          <Route
            path="/work"
            element={
              <Splash>
                <Navbar />
                <Works /> <FooterPage />
              </Splash>
            }
          />
          <Route
            path="/works/webion-virtual-online-shopping"
            element={
              <Splash>
                <Navbar />
                <WebionVirtualOnlineShopping />
                <FooterPage />
              </Splash>
            }
          />
          <Route
            path="/works/magnum-facility-management-erp"
            element={
              <Splash>
                <Navbar />
                <MagnumFacilityERP />
                <FooterPage />
              </Splash>
            }
          />
          <Route
            path="/works/digital-ngo-platform"
            element={
              <Splash>
                <Navbar />
                <DigitalNgo />
                <FooterPage />
              </Splash>
            }
          />
          <Route
            path="/works/bridgit-parenting-schooling"
            element={
              <Splash>
                <Navbar />
                <BridgitParenting />
                <FooterPage />
              </Splash>
            }
          />
          <Route
            path="/works/profitability-implementation-tracker"
            element={
              <Splash>
                <Navbar />
                <Profitability />
                <FooterPage />
              </Splash>
            }
          />
          <Route
            path="/works/sublime-client-management-system"
            element={
              <Splash>
                <Navbar />
                <SublimeClient />
                <FooterPage />
              </Splash>
            }
          />
          <Route
            path="/works/online-laundry-order-management-system"
            element={
              <Splash>
                <Navbar />
                <OnlineLaundary />
                <FooterPage />
              </Splash>
            }
          />
          <Route
            path="/works/underground-mines-erp"
            element={
              <Splash>
                <Navbar />
                <UndergroundMines />
                <FooterPage />
              </Splash>
            }
          />
          <Route
            path="/works/ai-based-health-monitoring-system"
            element={
              <Splash>
                <Navbar />
                <AIbasedHealth />
                <FooterPage />
              </Splash>
            }
          />
          <Route
            path="/works/solar-web-application"
            element={
              <Splash>
                <Navbar />
                <SolarWebApp />
                <FooterPage />
              </Splash>
            }
          />
          <Route
            path="/works/online-bidding-facilitating"
            element={
              <Splash>
                <Navbar />
                <OnlineBidding />
                <FooterPage />
              </Splash>
            }
          />
          <Route
            path="/works/material-testing-lab-automation"
            element={
              <Splash>
                <Navbar />
                <MaterialTesting />
                <FooterPage />
              </Splash>
            }
          />
          <Route
            path="/works/farmers-connect-application"
            element={
              <Splash>
                <Navbar />
                <FarmersConnectApp />
                <FooterPage />
              </Splash>
            }
          />
          <Route
            path="/works/shipping-management-solution"
            element={
              <Splash>
                <Navbar />
                <ShippingManagement />
                <FooterPage />
              </Splash>
            }
          />
          <Route
            path="/works/sports-ecosystem"
            element={
              <Splash>
                <Navbar />
                <SportsEcosystem />
                <FooterPage />
              </Splash>
            }
          />
          <Route
            path="/works/logistic-solution-erp"
            element={
              <Splash>
                <Navbar />
                <LogisticSol />
                <FooterPage />
              </Splash>
            }
          />
          <Route
            path="/careers"
            element={
              <Splash>
                <Navbar />
                <Careers />
                <FooterPage />
              </Splash>
            }
          />
          <Route
            path="/contact-us"
            element={
              <Splash>
                <Navbar />
                <ContactUs />
                <FooterPage />
              </Splash>
            }
          />
          <Route
            path="/privacy-policy"
            element={
              <Splash>
                <Navbar />
                <PrivacyPolicy />
                <FooterPage />
              </Splash>
            }
          />
        </Routes>
      </Router>
    </>
  );
}

export default App;
