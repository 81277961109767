import Partner1 from "../../assets/img/partnersLogo/partner1.png";
import Partner2 from "../../assets/img/partnersLogo/partner2.jpg";
import Partner3 from "../../assets/img/partnersLogo/partner3.jpg";
import Partner4 from "../../assets/img/partnersLogo/partner4.jpg";
import Partner5 from "../../assets/img/partnersLogo/partner5.png";
import Partner6 from "../../assets/img/partnersLogo/partner6.png";
import Partner7 from "../../assets/img/partnersLogo/partner7.png";
import Partner8 from "../../assets/img/partnersLogo/partner8.webp";
import Partner9 from "../../assets/img/partnersLogo/partner9.jpg";
import Partner10 from "../../assets/img/partnersLogo/partner10.jpg";
import Partner11 from "../../assets/img/partnersLogo/partner11.svg";
import Partner12 from "../../assets/img/partnersLogo/partner12.png";
import Partner13 from "../../assets/img/partnersLogo/partner13.png";
// import Partner14 from "../../img/partnersLogo/partner14.jpg";
// import Partner15 from "../../img/partnersLogo/partner15.jpg";
import Partner16 from "../../assets/img/partnersLogo/partner16.png";
import Partner17 from "../../assets/img/partnersLogo/partner17.webp";
import Partner18 from "../../assets/img/partnersLogo/partner18.png";
import Partner19 from "../../assets/img/partnersLogo/partner19.jpg";
import Partner20 from "../../assets/img/partnersLogo/partner20.webp";
export const partnersData = [
    {
        img: Partner1,
        title: "KHANDEL METAL WORKS"
    },
    {
        img: Partner2,
        title: "ABRADO"
    },
    {
        img: Partner3,
        title: "AIDBEES"
    },
    {
        img: Partner4,
        title: "KHANDELWAL ALANKAR KENDRA"
    },
    {
        img: Partner5,
        title: "GLOBAL ARCHER CONSULTANCY LLP"
    },
    {
        img: Partner6,
        title: "REWARD PORT"
    },
    {
        img: Partner7,
        title: "HEALIOM"
    },
    {
        img: Partner8,
        title: "MAGENTA"
    },
    {
        img: Partner9,
        title: "RENUKA CONSULTANTS"
    },
    {
        img: Partner10,
        title: "ANNADATA BHARAT"
    },
    {
        img: Partner11,
        title: "ACTIO SPORT"
    },
    {
        img: Partner12,
        title: "SAHYADHRI"
    },
    {
        img: Partner13,
        title: "ARISTON"
    },
    {
        img: Partner1,
        title: "KHANDEL METAL WORKS"
    },
    {
        img: Partner16,
        title: "TIRUPATI ENTERPRISES"
    },
    {
        img: Partner17,
        title: "BLACKTOP INFRASRUCTURE PVT LTD"
    },
    {
        img: Partner18,
        title: "SAMI INFOTECH"
    },
    {
        img: Partner19,
        title: "VIKRAM SOLAR"
    },
    {
        img: Partner20,
        title: "BRIDGIT"
    }
    
]