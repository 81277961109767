import React, { useState } from "react";
import "./WorkpageT1.scss";
import { BsFillCircleFill } from "react-icons/bs";
import SkillsCard from "../abtus/SkillsCard";
import { ReactComponent as Line } from "../../assets/icons/gradient_line.svg";
import { ReactComponent as Line2 } from "../../assets/icons/gradient lineL.svg";
import { CurvedText } from "../home/ScrollPercentage";
import { Helmet } from "react-helmet";
export default function WorkpageT1({
  bgImg,
  title,
  heading,
  subheading,
  boximg,
  purposedescription,
  benefitTitle,
  benefitsdescriptions,
  benefits,
  featureHeading,
  featuredescription,
  feature,
}) {
  const [selectedIndex, setSelectedIndex] = useState(9);

  const [expanded, setExpanded] = useState(false);
  const handleContentClick = (index) => {
    // Toggle the expanded state
    if (index === selectedIndex) {
      setSelectedIndex(9);

      setExpanded(!expanded);

      return;
    }
    setTimeout(() => {
      setSelectedIndex(index);
    }, 100);
    if (!expanded) setExpanded(!expanded);
    // if(selectedIndex != index )
    // setExpanded(!expanded);
  };
  return (
    <>
      <div>
        <Helmet>
          <title>{title} | Sublime Technocorp Pvt Ltd</title>
        </Helmet>
        <div className="work-page-t1-page">
          <div className="work-page-t1-container">
            <img src={bgImg} alt={title} className="work-page-banner-image" />
            <div className="work-page-banner-title-text-overlay">
              <span>
                <Line2 className="line" />
                <h1>{title}</h1>
                <Line className="line" />
              </span>
            </div>
          </div>
          <div className="work-page-t1-heading">
            <h2>{heading}</h2>
            <p>{subheading}</p>
          </div>
          {/* <div className="work-page-t1-purpose-div"> */}
          <div className="work-page-t1-purpose-container">
            <div className="work-page-t1-purpose-context">
              <div className="work-page-t1-purpose-image">
                <img src={boximg} alt="Purpose Img" />
              </div>
              <div className="work-page-t1-purpose-content-div">
                <div className="work-page-t1-purpose-content">
                  <h3>Purpose</h3>
                  <p>{purposedescription}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="work-page-t1-benefits">
            <div className="work-page-t1-benefits-title-div">
              <h3>{benefitTitle}</h3>
              <p>{benefitsdescriptions}</p>
            </div>
            <div className="work-page-t1-benefits-list-div">
              <ul type="none">
                {Object.values(benefits).map((benefit, index) => (
                  <li key={index}>
                    <BsFillCircleFill size={15} className="circle-icon" />{" "}
                    {benefit}{" "}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          {/* </div> */}
          <div className="work-page-t1-feature">
            <div className="work-page-t1-feature-title-div">
              <h3>{featureHeading}</h3>
              <p>{featuredescription}</p>
            </div>
            <div className="work-page-t1-feature-content-div">
              {feature.map((works, index) => (
                <SkillsCard
                  key={index}
                  index={index}
                  title={works.title}
                  subtitle={works.subtitle}
                  onClick={handleContentClick}
                  show={index === selectedIndex}
                />
              ))}
            </div>
          </div>
          <CurvedText />
        </div>
      </div>
    </>
  );
}
