import React, { useRef, useEffect } from "react";
import PartnerCard from "./PartnerCard";
import { partnersData } from "./PartnersData";
import "./Partners.scss";
import { useInView } from "react-intersection-observer";
import { ReactComponent as Line } from "../../assets/icons/gradient_line.svg";
import { ReactComponent as Line2 } from "../../assets/icons/gradient lineL.svg";
export default function Partners() {
  const { ref, inView } = useInView({
    triggerOnce: true, // Ensures the animation only plays once
    threshold: 0.5, // Adjust the visibility threshold as needed
  });
  const { ref1, inView1 } = useInView({
    triggerOnce: true, // Ensures the animation only plays once
    threshold: 0.5, // Adjust the visibility threshold as needed
  });
  const partnersCardsRef = useRef(null);

  useEffect(() => {
    const partnersCards = partnersCardsRef.current;
    if (partnersCards instanceof Element) {
      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            partnersCards.classList.add("zoom-in-animation");
            observer.unobserve(entry.target);
          }
        });
      });

      observer.observe(partnersCards); // Pass the actual DOM element

      return () => {
        observer.unobserve(partnersCards);
      };
    } else {
      console.error("partnersCards is not a valid element");
    }
  }, []);
  return (
    <>
      <div className="our-partners-container">
        <div ref={ref} className={inView ? "our-partners-div" : ""}>
          <h1>PARTNERS</h1>
          <span>
            <Line2 style={{marginBottom: "15px"}} />
            <p>OUR PARTNERS</p>
            <Line style={{marginBottom: "15px"}} />
          </span>
          <h3>
            YOUR SUCCESS, <br />
            OUR REPUTATION
          </h3>
        </div>
      </div>
      <div className="our-partners-section">
        <div
          className="our-partners-cards zoom-in-animation"
          ref={partnersCardsRef}
        >
          {partnersData.map((partners) => (
            <PartnerCard
              title={partners.title}
              img={partners.img}
              className="partner-card-content"
            />
          ))}
        </div>
      </div>
    </>
  );
}
