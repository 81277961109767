import React from "react";
import ContactUsBg from "../../assets/img/contact-us-bg.webp";
import "./ContactUs.scss";
import { IoLocationSharp } from "react-icons/io5";

import { CurvedText } from "../../components/home/ScrollPercentage";
import { ReactComponent as Line } from "../../assets/icons/gradient_line.svg";
import { ReactComponent as Line2 } from "../../assets/icons/gradient lineL.svg";
import { Helmet } from "react-helmet";
// import Location from "../../src/assets/icons/location.svg";
export default function ContactUs() {
  return (
    <>
      <Helmet>
        <title>Contact Us | Sublime Technocorp Pvt Ltd</title>
        </Helmet>
      <div className="contact-us-page">
        <div className="contact-us">
          <div className="reach-us-container">
            <div className="contact-us-container">
              <img
                src={ContactUsBg}
                alt="contact-us"
                className="contact-us-bg"
              />
              <div className="contact-us-title-text-overlay">
                <div className="contact-us-title-text">
                  <span>
                    <Line2 style={{ marginBottom: "10px" }} className="line-svg" />
                    <h6>WE HOPE TO WORK WITH YOU.</h6>
                    <Line style={{ marginBottom: "10px" }} className="line-svg" />
                  </span>
                  <h1>Let's Start a Project</h1>
                </div>
                <div className="reach-us-float-div">
                  <div className="reach-us-content-div">
                    <div className="reach-us-title-div">
                      <span>
                        <Line2 className="line-svg" />
                        <h3>REACH US</h3>
                        <Line className="line-svg" />
                      </span>
                      <div className="reach-us-title-subheading">
                        <h4>Write Us:</h4>
                        <p>info@sublimetechnocorp.com</p>
                        <p>hr@sublimetechnocorp.com</p>
                      </div>
                    </div>
                    <div className="reach-us-map-iframe">
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d30175.107691680045!2d73.02233!3d19.024636!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c3beae40c999%3A0xdf401668c1ee1643!2sSublime%20Technocorp%20Pvt.Ltd!5e0!3m2!1sen!2sin!4v1696936990244!5m2!1sen!2sin"
                        className="reach-us-map"
                        title="reach-us-map"
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="we-are-located">
          <h3>We Are Located At</h3>
          <div className="we-are-located-container">
            <div className="we-are-located-icon">
              <IoLocationSharp size={50} fill="#DB1111" />
            </div>
            <h4>INDIA</h4>
            <span>
              Haware Centurion Complex, <br />
              Office No: F21, Plot No: 88, Sector 19A, Seawoods, <br />
              Navi Mumbai, Maharashtra 400706
            </span>
          </div>
        </div>
        <div className="get-in-touch">
          <h2>Get In Touch</h2>
          <form className="get-in-touch-form-div">
            <div className="get-in-touch-form">
              <div className="get-in-touch-input-div">
                <input
                  placeholder="Your Name"
                  type="text"
                  className="get-in-touch-input"
                />
                <input
                  placeholder="Subject"
                  type="text"
                  className="get-in-touch-input"
                />
                <textarea
                  placeholder="Message"
                  type="text"
                  className="get-in-touch-text-area"
                />
              </div>
              <div className="get-in-touch-input-div">
                <input
                  placeholder="Your Email"
                  type="email"
                  className="get-in-touch-input"
                />
              </div>
            </div>

            <button className="get-in-touch-button">
              {/* <input type="submit" placeholder="Send Message" /> */}
              Send Message
            </button>
          </form>
        </div>
        <CurvedText />
      </div>
    </>
  );
}
