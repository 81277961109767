import React from "react";
import { materialAutomationData } from "../../components/work/WorkpageT1Data";
import WorkpageT1 from "../../components/work/WorkpageT1";
export default function MaterialTesting() {
  return (
    <>
      {materialAutomationData.map((work12) => (
        <WorkpageT1
          bgImg={work12.bgImg}
          title={work12.title}
          heading={work12.heading}
          boximg={work12.boximg}
          purposedescription={work12.purposedescription}
          benefitTitle={work12.benefitTitle}
          benefits={work12.benefits}
          featureHeading={work12.featureHeading}
          featuredescription={work12.featuredescription}
          feature={work12.feature}
        />
      ))}
    </>
  );
}
