import React, { useState } from "react";
import "./SkillsCard.scss";
import { MdKeyboardDoubleArrowDown } from "react-icons/md";
import { MdKeyboardArrowUp } from "react-icons/md";
export default function SkillsCard({ title, subtitle, show, onClick, index }) {
  const [showSubtitle, setShowSubtitle] = useState(false);
  const [showIcon, setShowIcon] = useState(false);
  const [openedIndex, setOpenedIndex] = useState(-1);
  const [clickedTitle, setClickedTitle] = useState(false);

  const toggleSubtitle = () => {
    setShowSubtitle(!showSubtitle);
    setShowIcon(!showIcon);
  };
  const handleTitleClick = () => {
    setClickedTitle(true);
    onClick(index);
  };
  return (
    <>
      <div className="skills-card">
        <div style={{ cursor: "pointer" }}>
          {show ? (
            <MdKeyboardArrowUp size={20} fill="#214a7d" className="arrow-icon" />
          ) : (
            <MdKeyboardDoubleArrowDown size={20} fill="#214a7d" className="arrow-icon" />
          )}
        </div>

        <div className="skills-card-content">
          <span onClick={handleTitleClick} className={clickedTitle ? "clicked" : ""}
           >{title}</span>
          {show && <p dangerouslySetInnerHTML={{ __html: subtitle }}></p>}
        </div>
      </div>
    </>
  );
}
