import cover1 from "../../assets/img/works/workcover1.jpg";
import cover2 from "../../assets/img/works/workcover2.png";
import cover3 from "../../assets/img/works/workcover3.jpg";
import cover4 from "../../assets/img/works/workcover4.jpg";
import cover5 from "../../assets/img/works/workcover5.jpg";
import cover6 from "../../assets/img/works/workcover6.jpg";
import cover7 from "../../assets/img/works/workcover7.jpg";
import cover8 from "../../assets/img/works/workcover8.jpg";
import cover9 from "../../assets/img/works/workcover9.jpg";
import cover10 from "../../assets/img/works/workcover10.webp";
import cover11 from "../../assets/img/works/workcover11.jpg";
import cover12 from "../../assets/img/works/workcover12.jpg";
import cover13 from "../../assets/img/works/workcover13.jpg";
import cover14 from "../../assets/img/works/workcover14.jpg";
import cover15 from "../../assets/img/works/workcover15.jpg";
import cover16 from "../../assets/img/works/workcover16.jpg";

export const WorkCardData=[
    {
        title: "Webion Virtual Online Shopping",
        coverimg: cover1,
        worklink: "/works/webion-virtual-online-shopping"
    },
    {
        title: "Magnum Facility Management ERP",
        coverimg: cover2,
        worklink: "/works/magnum-facility-management-erp"
    },
    {
        title: "Digital NGO Platform",
        coverimg: cover3,
        worklink: "/works/digital-ngo-platform"
    },
    {
        title: "Bridgit Parenting & Schooling App",
        coverimg: cover4,
        worklink: "/works/bridgit-parenting-schooling"
    },
    {
        title: "Profitability & Implementation Tracker",
        coverimg: cover5,
        worklink: "/works/profitability-implementation-tracker"
    },
    {
        title: "Sublime Client Management System",
        coverimg: cover6,
        worklink: "/works/sublime-client-management-system"
    },
    {
        title: "Online Laundry – Order Management System",
        coverimg: cover7,
        worklink: "/works/online-laundry-order-management-system"
    },
    {
        title: "Logistics Solutions ERP",
        coverimg: cover8,
        worklink: "/works/logistic-solution-erp"
    },
    {
        title: "Sports Ecosystem",
        coverimg: cover9,
        worklink: "/works/sports-ecosystem"
    },
    {
        title: "Shipping Management Solution",
        coverimg: cover10,
        worklink: "/works/shipping-management-solution"
    },
    {
        title: "Farmers Connect Application",
        coverimg: cover11,
        worklink: "/works/farmers-connect-application"
    },
    {
        title: "Material Testing & Lab Automation",
        coverimg: cover12,
        worklink: "/works/material-testing-lab-automation"
    },
    {
        title: "Online Bidding Facilitating",
        coverimg: cover13,
        worklink: "/works/online-bidding-facilitating"
    },
    {
        title: "Solar Web Application",
        coverimg: cover14,
        worklink: "/works/solar-web-application"
    },
    {
        title: "AI Based Health Monitoring",
        coverimg: cover15,
        worklink: "/works/ai-based-health-monitoring-system"
    },
    {
        title: "Under Ground Mines ERP",
        coverimg: cover16,
        worklink: "/works/underground-mines-erp"
    }
    
]