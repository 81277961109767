import React from "react";
import { WebionVirtualShopping } from "../../components/work/WorkpageT1Data";
import WorkpageT1 from "../../components/work/WorkpageT1";

export default function WebionVirtualOnlineShopping() {
  // const benefits = WebionVirtualShopping[0].benefits;
  return (
    <>
      {WebionVirtualShopping.map((work1) => (
        <WorkpageT1
          title={work1.title}
          bgImg={work1.bgImg}
          heading={work1.heading}
          purposedescription={work1.purposedescription}
          benefitTitle={work1.benefitTitle}
          benefitsdescriptions={work1.benefitsdescriptions}
          benefits={work1.benefits}
          boximg={work1.boximg}
          featureHeading={work1.featureHeading}
          featuredescription={work1.featuredescription}
          feature={work1.feature}
        />
      ))}
    </>
  );
}
