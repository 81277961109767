import React from "react";
import "./Careers.scss";
import CareersBackground from "../../assets/img/CareersBg.jpg";
import { careersCardData } from "../../components/careers/careersData";
import CareersCard from "../../components/careers/careersCard.js";
import { useInView } from "react-intersection-observer";
import { ReactComponent as Line } from "../../assets/icons/gradient_line.svg";
import { ReactComponent as Line2 } from "../../assets/icons/gradient lineL.svg";
import { CurvedText } from "../../components/home/ScrollPercentage";
import { Helmet } from "react-helmet";
export default function Careers() {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });
  const [ref1, inView1] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });
  const [ref2, inView2] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });
  const [ref3, inView3] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });
  const [ref4, inView4] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  return (
    <>
    <Helmet>
      <title>Careers | Sublime Technocorp Pvt Ltd</title>
    </Helmet>
      <div className="careers-page">
        <div className="careers-container">
          <img
            src={CareersBackground}
            alt="Careers Background"
            className="careers-banner-image"
          />
          <div
            ref={ref}
            className={inView ? "careers-banner-title-text-overlay" : ""}
          >
            <h1>Careers</h1>
            <p>A life cycle of training, assessment, and deployment</p>
          </div>
        </div>
        <div className="careers-content">
          <div className="careers-content-div">
            <div ref={ref1} className={inView1 ? "careers-content-left" : ""}>
              <h4>
                We create an advantage of screening and training to build the
                right human resources for client deployment.
              </h4>
            </div>
            <div ref={ref1} className={inView1 ? "careers-content-right" : ""}>
              <span>
                At Sublime, we do not just offer career opportunities but
                believe in a full-fledged development of human resources. Our
                career initiatives are planned across a timeframe of strategic
                screening, training, assessment, and deployment. We do this to
                offer unconventionally trained, bespoke, and experienced human
                resources to be deployed at our client site.
              </span>
            </div>
          </div>
        </div>
        <div className="employee-content">
          <div className="employee-title-block">
            <h1>CAREERS</h1>
          </div>
          <div className="employee-line-shape">
            <span>
              <Line2 style={{ marginBottom: "15px" }} />
              <p>EMPLOYEE LIFE CYCLE</p>
              <Line style={{ marginBottom: "15px" }} />
            </span>
          </div>
          <div ref={ref2} className={inView2 ? "employee-title-block-lg" : ""}>
            <h3>HIRING PROCESS</h3>
          </div>
        </div>
        <div ref={ref2} className="careers-category-div">
          <div className="careers-category">
            {careersCardData.map((career) => (
              <CareersCard
                icon={career.icon}
                careerTitle={career.careerTitle}
                careerSubheading={career.careerSubheading}
              />
            ))}
          </div>
        </div>
        <CurvedText />
      </div>
    </>
  );
}
