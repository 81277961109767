import React from "react";
import { logisticSolution } from "../../components/work/WorkpageT1Data";
import WorkpageT1 from "../../components/work/WorkpageT1.jsx";

export default function LogisticSol() {
  return (
    <>
      {logisticSolution.map((work8) => (
        <WorkpageT1
          title={work8.title}
          bgImg={work8.bgImg}
          heading={work8.heading}
          boximg={work8.boximg}
          purposedescription={work8.purposedescription}
          benefitTitle={work8.benefitTitle}
          benefitsdescriptions={work8.benefitsdescriptions}
          benefits={work8.benefits}
          featureHeading={work8.featureHeading}
          featuredescription={work8.featuredescription}
          feature={work8.feature}
        />
      ))}
    </>
  );
}
