import React from 'react';
import {bridgitParenting} from "../../components/work/WorkpageT1Data";
import WorkpageT1 from "../../components/work/WorkpageT1";

export default function BridgitParenting() {
  return (
    <>
        {bridgitParenting.map((work4) => (
            <WorkpageT1
                bgImg={work4.bgImg}
                title={work4.title}
                heading={work4.heading}
                purposedescription={work4.purposedescription}
                benefitTitle={work4.benefitTitle}
                benefitsdescriptions={work4.benefitsdescriptions}
                benefits={work4.benefits}
                boximg={work4.boximg}
                featureHeading={work4.featureHeading}
                featuredescription={work4.featuredescription}
                feature={work4.feature}
            />
        ))}
    </>
  )
}
