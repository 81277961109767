import React from "react";
import ProductDevelopmentBanner from "../../assets/img/services/dedicated-developers-bg.jpg";
import "./ProductDevelopment.scss";
import { useInView } from "react-intersection-observer";
import { CurvedText } from "../../components/home/ScrollPercentage";
import { ReactComponent as Line } from "../../assets/icons/gradient_line.svg";
import { ReactComponent as Line2 } from "../../assets/icons/gradient lineL.svg";
import { Helmet } from "react-helmet";
export default function ProductDevelopment() {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });
  const [ref1, inView1] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });
  const [ref2, inView2] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  return (
    <>
      <Helmet>
        <title>Custom Product Development Services & Teams</title>
      </Helmet>
      <div className="product-development-page">
        <div className="product-development">
          <img
            src={ProductDevelopmentBanner}
            alt="Product Development"
            className="product-development-img"
          />
          <div className="product-development-text-overlay">
            <span>
              <Line2 className="line" />
              <h1>Product Development Services</h1>
              <Line className="line" />
            </span>
          </div>
        </div>
        <div ref={ref} className={inView ? "minimum-viable-product" : ""}>
          <div className="minimum-viable-product-div">
            <h4>Minimum Viable Product</h4>
            <p>
              Looking for building a minimum viable product that is primarily
              designed with minimum features, minimum time & minimum budget? We
              build it for you so you can validate your idea or product. At
              Sublime Technocorp, we help you design, build & launch your MVP in
              the minimal time possible.
            </p>
          </div>
        </div>
        <div className="build-for-scale">
          <div className="build-for-scale-div">
            <h4>
              <b>Build for Scale</b>
            </h4>
            <p>
              At Sublime Technocorp, we want to help you every step of the way
              when it comes to software product development – from the very
              beginning stages of defining your product goals to the design and
              architecture, all the way to launching it for scale. Our team is
              composed of designers, technical product leaders, solution
              architects, and senior software engineers who collectively have
              years of experience in building successful software products.
            </p>
          </div>
        </div>
        <div ref={ref1} className={inView1 ? "software-product-line" : ""}>
          <h4>Software Product Line Engineering</h4>
          <p>
            A software product line is a group of similar products that share
            common components. We will help you establish a software product
            line by identifying the common systems in your product or for your
            domain. Our team of Product Owners along with Solution Architects
            will set up a software product line for you. This will allow you to
            build an array of similar products from the common components.
          </p>
        </div>
        <div className="product-reengineering">
          <div className="build-for-scale-div">
            <h4>Product Reengineering</h4>
            <p>
              At some point, every software product needs a little bit of
              fine-tuning. Whether it’s because you want to change the internal
              code structure, enhance technical speed, or just need to modernize
              the product to scale demand and behaviour. We serve all your
              product re-engineering needs from a technical outlook. So, if
              you’re looking for someone to help you refactor your existing
              software product, we’re your team! <br />
              At Sublime Technocorp, we specialize in software re-engineering.
              Our team engages with you to understand your software product
              requirement and we work to find solutions that remove technical
              glitches and improve scalability, reliability, and flexibility. We
              are committed to providing you with the best possible solution for
              your product.
            </p>
          </div>
        </div>
        <div ref={ref2} className={inView2 ? "software-product-line" : ""}>
          <h4>Product Makeovers</h4>
          <p>
            Software products need to be kept up-to-date and improved regularly
            so that they stay relevant in the market. Our team at Sublime
            Technologies uses an agile approach when it comes to making changes
            – we first analyse and prioritize the change, then implement it
            after it’s been peer-reviewed and tested. By doing this, we can be
            confident that each change is well addressed before taking it live.
          </p>
          <p>
            At Sublime Technocorp, we believe that small, continuous, and
            incremental changes are essential to keeping your software products
            relevant in the market.
          </p>
        </div>
        <CurvedText />
      </div>
    </>
  );
}
