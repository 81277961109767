import React from "react";
import DataAnalyticsBanner from "../../assets/img/services/Digital-Marketing.jpg";
import DataAnalyticsImg from "../../assets/img/services/Data-Analytics-Services.jpg";
import "./DataAnalytics.scss";
import { useInView } from "react-intersection-observer";
import { ReactComponent as Line } from "../../assets/icons/gradient_line.svg";
import { ReactComponent as Line2 } from "../../assets/icons/gradient lineL.svg";
import {CurvedText} from "../../components/home/ScrollPercentage";
import { Helmet } from "react-helmet";
export default function DataAnalytics() {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  const [ref1, inView1] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  const [ref2, inView2] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  return (
    <>
    <Helmet>
      <title>Data Analytics Services & Consulting | Sublime Technocorp</title>
    </Helmet>
      <div className="data-analytics-page">
        <div className="data-analytics">
          <img
            src={DataAnalyticsBanner}
            alt="Custom Software"
            className="data-analytics-bg"
          />
          <div className="data-analytics-text-overlay">
            <span>
              <Line2 className="line-svg" />
              <h1>Data Analytics Services</h1>
              <Line className="line-svg" />
            </span>
          </div>
        </div>
        <div className="data-analytics-content">
          <div ref={ref} className={inView ? "data-analytics-content-div" : ""}>
            <h4>Data Analytics Services</h4>
            <p>
              To make better business decisions, you need to be data-driven. We
              help start-ups and enterprises to turn their raw data into
              actionable insights with interactive data dashboards, reports, and
              visual data analytics services and solutions.
            </p>
            <p>
              At our company, data engineering scientists have a proven track
              record of creating business dashboards that are easy to understand
              and visualize. With our comprehensive data management solutions
              and visualization services, you can make informed business
              decisions quickly.
            </p>
          </div>
        </div>
        <div className="data-analytics-banner-container">
          <div className="data-analytics-banner-context">
            <div
              ref={ref1}
              className={inView1 ? "data-analytics-banner-bg-div" : ""}
            >
              <img src={DataAnalyticsImg} alt="Data Analytics" />
            </div>
            <div className="data-analytics-banner-content-div">
              <div className="data-analytics-banner-text">
                <div
                  ref={ref2}
                  className={
                    inView2 ? "data-analytics-banner-text-overlay" : ""
                  }
                >
                  <div className="data-analytics-banner-text-overlay-div">
                    <span>
                      At Sublime Technocorp, we understand how important it is
                      for business owners to have a user-friendly business model
                      that can store data easily and efficiently. That's why our
                      data analytics services and solutions are designed to
                      convert large chunks of multipart and different data into
                      easy-to-understand data that you can access with just a
                      few clicks. With our solutions, you'll be able to make
                      enhanced business decisions with ease and confidence.
                    </span>
                    <br />
                    <span>
                      Our data scientists are experienced in collecting data
                      from a multitude of sources, such as large data
                      warehouses, web streams, and social media. This provides
                      you with a comprehensive analysis of your business. In
                      addition, our data analytics consulting services assist
                      you in turning your complex data into spontaneous reports
                      and interactive dashboards. This, in turn, helps you to
                      understand your customer behavior and make better
                      decisions.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CurvedText />
      </div>
    </>
  );
}
