import React from "react";
import CloudTansformationBg from "../../assets/img/services/cloud-transformation-thumb.jpg";
import CloudTransformationImg from "../../assets/img/services/cloud-transformation1.jpg";
import CloudTansformationBanner from "../../assets/img/services/commercial-glass-buildings-reflection-sun-rays-blue-web-header.jpg";
import "./CloudTransformation.scss";
import { useInView } from "react-intersection-observer";
import { CurvedText } from "../../components/home/ScrollPercentage";
import { ReactComponent as Line } from "../../assets/icons/gradient_line.svg";
import { ReactComponent as Line2 } from "../../assets/icons/gradient lineL.svg";
import { Helmet } from "react-helmet";
export default function CloudTransformation() {
  const [ref1, inView1] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });
  const [ref2, inView2] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });
  const [ref3, inView3] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  return (
    <>
    <Helmet>
      <title>Cloud And Digital Transformation Services</title>
    </Helmet>
      <div className="cloud-transformation-page">
        <div className="cloud-transformation">
          <img
            src={CloudTansformationBg}
            alt="Cloud Transformation"
            className="cloud-transformation-bg"
          />
          <div className="cloud-transformation-text-overlay">
            <span>
              <Line2 className="line-svg" />
              <h1>Cloud Transformation</h1>
              <Line className="line-svg" />
            </span>
          </div>
        </div>
        <div className="cloud-transformation-content">
          <div
            ref={ref1}
            className={inView1 ? "cloud-transformation-content-div" : ""}
          >
            <h3>Cloud And Digital Transformation</h3>
            <p>
              As we move further into the digital age, it’s becoming more and
              more essential for businesses to have a strong presence online. To
              keep up with the competition, your business needs to be able to
              adapt quickly to changes in the market. This means generating
              technical capabilities and having a cloud application strategy so
              that you can deliver and manage your products and services swiftly
              and efficiently. At Sublime Technocorp we can help you design the
              right cloud agile strategy to help you achieve all of your
              business goals using cloud and digital transformations.
            </p>
            <br />
            <p>
              Our agile approach to cloud computing and its strategy can help
              you move your business functions to private, public, hybrid, and
              multi-cloud software solutions. You can thus take advantage of and
              adopt the best features of each type of cloud platform and tailor
              it to your specific needs.
            </p>
          </div>
        </div>
        <div className="cloud-transformation-banner-container">
          <div className="cloud-transformation-banner-context">
            <div
              ref={ref2}
              className={inView2 ? "cloud-transformation-banner-bg-div" : ""}
            >
              <img src={CloudTransformationImg} alt="Cloud transformation" />
            </div>
            <div className="cloud-transformation-banner-content-div">
              <div className="cloud-transformation-banner-text">
                <div
                  ref={ref2}
                  className={
                    inView2 ? "cloud-transformation-banner-text-overlay" : ""
                  }
                >
                  <span>
                    At Sublime Technocorp, we want to help you take your current
                    or legacy software solutions and modernize them for the
                    cloud. We specialize in building and deploying cloud
                    applications at scale and our cloud application strategy is
                    the perfect solution for your business. Our team has a
                    wealth of experience in setting up private, public, hybrid,
                    and multi-cloud applications - ensuring that your users have
                    the best possible experience. Our cloud applications are
                    built using the latest technologies from AWS and Microsoft
                    Azure- our cloud partners.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="cloud-transformation-context-container">
          <img
            src={CloudTansformationBanner}
            alt="Banner"
            className="cloud-transformation-banner"
          />
          <div
            // ref={ref3}
            // className={
            //   inView3 ? "cloud-transformation-context-text-overlay" : ""
            // }
            className="cloud-transformation-context-text-overlay"
          >
            <p>
              If you’re looking to migrate your business’s on-premises software
              application to the cloud, you need a relatively fast and
              inexpensive migration procedure. That’s where we come in. Our
              engineers can help you make the move smoothly and efficiently.
              With our cloud migration solutions, we’ll migrate the exact copy
              of your workload and application (its OS and data) from one kind
              of IT environment to the other – primarily from on-premises to
              private or public cloud.
            </p>
            <p>
              Our approach is designed to be fast, smooth, and require less
              labor. With our extensible cloud computing power, networking
              infrastructure, and storage, you can leverage the power of the
              cloud to migrate your applications quickly and easily.
            </p>
            <p>
              To run your software applications more efficiently, your
              organization should consider making a cultural shift to DevOps.
              DevOps is the combination of development and operations which can
              automate the software development pipeline. Through DevOps, your
              business can improve communication and collaboration between
              developers and IT operations. This will result in faster delivery
              of products and services, increased quality, and reduced costs.
            </p>
            <p>
              By implementing DevOps, we have been able to provide our clients
              with more efficient and automated CI/CD pipelines. This has saved
              on costs, increased software iteration cycles, and given us the
              ability to run software with greater fault tolerance.
            </p>
          </div>
        </div>
        <CurvedText />
      </div>
    </>
  );
}
