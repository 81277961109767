import React from "react";
import "./Works.scss";
import WorkBg from "../../assets/img/services/dedicated-developers-bg.jpg";
import { WorkCardData } from "../../components/work/WorksCardData";
import WorkCard from "../../components/work/WorkCard";
import { ReactComponent as Line } from "../../assets/icons/gradient_line.svg";
import { ReactComponent as Line2 } from "../../assets/icons/gradient lineL.svg";
import { CurvedText } from "../../components/home/ScrollPercentage";
import { Helmet } from "react-helmet";
export default function Works() {
  return (
    <>
      <div>
        <Helmet>
          <title>work | Sublime Technocorp Pvt Ltd</title>
        </Helmet>
        <div className="works">
          <div className="works-page">
            <img src={WorkBg} alt="Works" className="works-page-img" />
            <div className="works-text-overlay">
              <span>
                <Line2 />
                <h1>Works</h1>
                <Line />
              </span>
            </div>
          </div>
          <div className="works-conatiner">
            <div className="works-section">
              {WorkCardData.map((item) => (
                <WorkCard
                  title={item.title}
                  coverimg={item.coverimg}
                  worklink={item.worklink}
                />
              ))}
            </div>
          </div>
        </div>
        <CurvedText />
      </div>
    </>
  );
}
