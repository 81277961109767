import React, { useState } from "react";
import "./ServicesCard.scss";
import { BsHourglassSplit } from "react-icons/bs";
import { BsArrowUpRight } from "react-icons/bs";
import Service1 from "../../assets/img/serviceimage1.webp";
import Service2 from "../../assets/img/serviceimage2.jpg";
import Service3 from "../../assets/img/serviceimage3.jpg";
import Service4 from "../../assets/img/serviceimage4.jpg";
import Service5 from "../../assets/img/serivceimage5.jpg";
import { ReactComponent as Hourglass } from "../../assets/icons/hourglass.svg";
// import FontAwesomeIcon from "font-awesome";
export function TempServicesCard() {
  return (
    <div>
      <section
        class="animated fadeIn service-card-div"
        data-id="caf13a3"
        data-element_type="section"
        data-settings='{"background_background":"classic","animation":"fadeIn","stretch_section":"section-stretched"}'
        // style={{ width: "33.33%", left: "0px", background: "#FFF" }}
      >
        <div class="service-container">
          <div class="" data-id="89b59a0" data-element_type="column">
            <div class="">
              <div
                class=""
                data-id="f5ec285"
                data-element_type="widget"
                data-settings='{"_animation":"fadeInDown","_animation_tablet":"fadeIn","_animation_mobile":"fadeIn"}'
                data-widget_type="dsn_service.default"
              >
                <div class="service-item p-relative grid-item">
                  <div className="service-item-div" data-dsn-iso="f5ec285">
                    <div className="service-card-div-border">
                      <div class="service-item-inner  over-hidden p-relative h-100 box-hover-image v-light background-transparent services-card-box">
                        <div class="box-img-shadow p-absolute">
                          <div
                            class="img-box-parallax before-z-index"
                            data-overlay="0"
                          >
                            <img
                              loading="lazy"
                              width="880"
                              height="611"
                              src={Service1}
                              class=" cover-bg-img "
                              alt=""
                              decoding="async"
                              data-dsn-speed="30%"
                              srcSet={Service1}
                              sizes="(max-width: 880px) 100vw, 880px"
                              style={{ objectPosition: "50% 50%" }}
                            />
                          </div>
                        </div>
                        <div class=" mb-20 text-center  ">
                          <Hourglass width={40} height={40} fill="#214a7d"  />
                        </div>
                        <div class="service-content p-relative">
                          <h4 class="service-title-h4 text-center ">
                            CLOUD TRANSFORMATION
                          </h4>
                          <div class="w-100 pt-20 mt-20 border-top text-center">
                            <a
                              href="/services/cloud-and-digital-transformation-service"
                              rel="nofollow"
                              class="link-vist p-relative dsn-btn-link body-color "
                            >
                              <span class="content-link d-flex align-items-center justify-content-center ">
                                <span class="info-content body-color link-vist-text hover-line-bottom  heading-font">
                                  KNOW MORE
                                </span>
                                <svg viewBox="0 0 80 80" class="ml-5">
                                  <polyline points="19.89 15.25 64.03 15.25 64.03 59.33"></polyline>
                                  <line
                                    x1="64.03"
                                    y1="15.25"
                                    x2="14.03"
                                    y2="65.18"
                                  ></line>
                                </svg>
                              </span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="service-card-div-border">
                      <div class="service-item-inner  over-hidden p-relative h-100 box-hover-image v-light background-transparent">
                        <div class="box-img-shadow p-absolute">
                          <div
                            class="img-box-parallax before-z-index"
                            data-overlay="0"
                          >
                            <img
                              loading="lazy"
                              width="853"
                              height="1024"
                              src={Service2}
                              class=" cover-bg-img "
                              alt=""
                              decoding="async"
                              data-dsn-speed="30%"
                              srcSet={Service2}
                              sizes="(max-width: 853px) 100vw, 853px"
                              style={{ objectPosition: "50% 50%" }}
                            />
                          </div>
                        </div>
                        <div class=" mb-20 text-center  ">
                          <Hourglass width={40} height={40} fill="#214a7d"  />
                        </div>
                        <div class="service-content p-relative">
                          <h4 class="service-title-h4 text-center">
                            DEDICATED DEVELOPERS
                          </h4>
                          <div class="service_description mt-20">
                            <p></p>
                          </div>
                          <div class="w-100 pt-20 mt-20 border-top text-center ">
                            <a
                              href="/services/dedicated-developers"
                              rel="nofollow"
                              class="link-vist p-relative dsn-btn-link body-color "
                            >
                              <span class="content-link d-flex align-items-center">
                                <span class="info-content body-color link-vist-text hover-line-bottom  heading-font">
                                  KNOW MORE
                                </span>
                                <svg viewBox="0 0 80 80" class="ml-5">
                                  <polyline points="19.89 15.25 64.03 15.25 64.03 59.33"></polyline>
                                  <line
                                    x1="64.03"
                                    y1="15.25"
                                    x2="14.03"
                                    y2="65.18"
                                  ></line>
                                </svg>
                              </span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="service-card-div-border">
                      <div class="service-item-inner  over-hidden p-relative h-100 box-hover-image v-light background-transparent">
                        <div class="box-img-shadow p-absolute">
                          <div
                            class="img-box-parallax before-z-index"
                            data-overlay="0"
                          >
                            <img
                              loading="lazy"
                              width="960"
                              height="640"
                              src={Service3}
                              class=" cover-bg-img "
                              alt=""
                              decoding="async"
                              data-dsn-speed="30%"
                              srcSet={Service3}
                              sizes="(max-width: 960px) 100vw, 960px"
                              style={{ objectPosition: "50% 50%" }}
                            />
                          </div>
                        </div>
                        <div class=" mb-20 text-center  ">
                          <Hourglass width={40} height={40} fill="#214a7d"  />
                        </div>
                        <div class="service-content p-relative">
                          <h4 class="service-title-h4 text-center ">
                            CUSTOM SOFTWARE DEVELOPMENT
                          </h4>
                          <div class="service_description mt-20">
                            <p></p>
                          </div>
                          <div class="w-100 pt-20 mt-20 border-top text-center">
                            <a
                              href="/services/custom-software-development"
                              rel="nofollow"
                              class="link-vist p-relative dsn-btn-link body-color "
                            >
                              <span class="content-link d-flex align-items-center">
                                <span class="info-content body-color link-vist-text hover-line-bottom  heading-font">
                                  KNOW MORE
                                </span>
                                <svg viewBox="0 0 80 80" class="ml-5">
                                  <polyline points="19.89 15.25 64.03 15.25 64.03 59.33"></polyline>
                                  <line
                                    x1="64.03"
                                    y1="15.25"
                                    x2="14.03"
                                    y2="65.18"
                                  ></line>
                                </svg>
                              </span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="service-card-div-border">
                      <div class="service-item-inner  over-hidden p-relative h-100 box-hover-image v-light background-transparent">
                        <div class="box-img-shadow p-absolute">
                          <div
                            class="img-box-parallax before-z-index"
                            data-overlay="0"
                          >
                            <img
                              loading="lazy"
                              width="960"
                              height="640"
                              src={Service4}
                              class=" cover-bg-img "
                              alt=""
                              decoding="async"
                              data-dsn-speed="30%"
                              srcset={Service4}
                              sizes="(max-width: 960px) 100vw, 960px"
                              style={{ objectPosition: "50% 50%" }}
                            />
                          </div>
                        </div>
                        <div class=" mb-20 text-center  ">
                          <Hourglass width={40} height={40} fill="#214a7d"  />
                        </div>
                        <div class="service-content p-relative">
                          <h4 class="service-title-h4 text-center ">
                            PRODUCT DEVELOPMENT SERVICE
                          </h4>
                          <div class="service_description mt-20">
                            <p></p>
                          </div>
                          <div class="w-100 pt-20 mt-20 border-top text-center ">
                            <a
                              href="/services/product-development-services"
                              target="_blank"
                              // rel="nofollow"
                              class="link-vist p-relative dsn-btn-link body-color "
                              rel="noreferrer"
                            >
                              <span class="content-link d-flex align-items-center">
                                <span class="info-content body-color link-vist-text hover-line-bottom  heading-font">
                                  Know More
                                </span>
                                <svg viewBox="0 0 80 80" class="ml-5">
                                  <polyline points="19.89 15.25 64.03 15.25 64.03 59.33"></polyline>
                                  <line
                                    x1="64.03"
                                    y1="15.25"
                                    x2="14.03"
                                    y2="65.18"
                                  ></line>
                                </svg>
                              </span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="service-card-div-border">
                      <div class="service-item-inner  over-hidden p-relative h-100 box-hover-image v-light background-transparent">
                        <div class="box-img-shadow p-absolute">
                          <div
                            class="img-box-parallax before-z-index"
                            data-overlay="0"
                          >
                            <img
                              loading="lazy"
                              width="960"
                              height="540"
                              src={Service5}
                              class=" cover-bg-img "
                              alt=""
                              decoding="async"
                              data-dsn-speed="30%"
                              srcSet={Service5}
                              sizes="(max-width: 960px) 100vw, 960px"
                              style={{ objectPosition: "50% 50%" }}
                            />
                          </div>
                        </div>
                        <div class=" mb-20 text-center  ">
                          <Hourglass width={40} height={40} fill="#214a7d"  />
                        </div>
                        <div class="service-content p-relative">
                          <h4 class="service-title-h4 text-center ">
                            DATA ANALYTICS
                          </h4>
                          <div class="service_description mt-20">
                            <p></p>
                          </div>
                          <div class="w-100 pt-20 mt-20 border-top text-center ">
                            <a
                              href="/services/data-analytics-services"
                              target="_blank"
                              rel="noreferrer"
                              class="link-vist p-relative dsn-btn-link body-color "
                            >
                              <span class="content-link d-flex align-items-center">
                                <span class="info-content body-color link-vist-text hover-line-bottom  heading-font">
                                  Know More
                                </span>
                                <svg viewBox="0 0 80 80" class="ml-5">
                                  <polyline points="19.89 15.25 64.03 15.25 64.03 59.33"></polyline>
                                  <line
                                    x1="64.03"
                                    y1="15.25"
                                    x2="14.03"
                                    y2="65.18"
                                  ></line>
                                </svg>
                              </span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
