import React from "react";
import { UndergroundMinesData } from "../../components/work/WorkpageT1Data";
import WorkpageT2 from "../../components/work/WorkpageT2.jsx";
export default function UndergroundMines() {
  return (
    <>
      {UndergroundMinesData.map((work16) => (
        <WorkpageT2
          bgImg={work16.bgImg}
          title={work16.title}
          heading={work16.heading}
          boximg={work16.boximg}
          purposedescription={work16.purposedescription}
          benefitBg={work16.benefitBg}
          benefitTitle={work16.benefitTitle}
          benefitsdescriptions={work16.benefitsdescriptions}
          benefits={work16.benefits}
          featureHeading={work16.featureHeading}

          feature={work16.feature}
        />
      ))}
    </>
  );
}
