import React from 'react';
import { profitabilityTracker } from '../../components/work/WorkpageT1Data';
import WorkpageT1 from '../../components/work/WorkpageT1';
export default function ProfitabilityImplementation() {
  return (
    <>
      {profitabilityTracker.map((work5) => (
          <WorkpageT1 
          title={work5.title}
          bgImg={work5.bgImg}
          heading={work5.heading}
          purposedescription={work5.purposedescription}
          benefitTitle={work5.benefitTitle}
          benefitsdescriptions={work5.benefitsdescriptions}
          benefits={work5.benefits}
          boximg={work5.boximg}
          featureHeading={work5.featureHeading}
          featuredescription={work5.featuredescription}
          feature={work5.feature}
          />
      ))}
    </>
  )
}
