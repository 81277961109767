import React from "react";
import "./CustomSoftware.scss";
import CustomSoftwareBanner from "../../assets/img/services/dedicated-developers-bg.jpg";
import CustomSoftwareImg from "../../assets/img/services/custom-software-development.jpg";
import CustomSoftwareBuild from "../../assets/img/services/commercial-glass-buildings-reflection-sun-rays-blue-web-header.jpg";
import { useInView } from "react-intersection-observer";
import { ReactComponent as Line } from "../../assets/icons/gradient_line.svg";
import { ReactComponent as Line2 } from "../../assets/icons/gradient lineL.svg";
import { CurvedText } from "../../components/home/ScrollPercentage";
import { Helmet } from "react-helmet";
export default function CustomSoftware() {
  const [ref1, inView1] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });
  const [ref2, inView2] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });
  const [ref3, inView3] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });
  return (
    <>
      <Helmet>
        <title>Custom Software Development Services | Sublime Technocorp</title>
      </Helmet>

      <div className="custom-software-page">
        <div className="custom-software">
          <img
            src={CustomSoftwareBanner}
            alt="Custom Software"
            className="Custom-Software-bg"
          />
          <div className="custom-software-text-overlay">
            <span>
              <Line2 className="line-svg" />
              <h1>Custom Software Development</h1>
              <Line className="line-svg" />
            </span>
          </div>
        </div>
        <div className="custom-software-content">
          <div
            ref={ref1}
            className={inView1 ? "custom-software-content-div" : ""}
          >
            <div>
              <p>
                Creating a digitized customer experience that is available on
                all platforms is more important than ever. At Sublime
                Technocorp, we specialize in creating custom software
                development and mobile applications that are native or hybrid,
                depending on what our customers and their users need.
              </p>
            </div>
            <div>
              <p>
                Whether it’s a unique idea or creating mobile apps to serve
                existing users or migrating legacy mobile apps to newer hybrid
                platforms, our technical leads and mobile app developers are
                experts in understanding customer needs and delivering quality
                results.
              </p>
            </div>
          </div>
        </div>
        <div className="custom-software-banner-container">
          <div className="custom-software-banner-context">
            <div className="custom-software-banner-bg-div">
              <img
                ref={ref2}
                src={CustomSoftwareImg}
                alt="Cloud transformation"
                className={inView2 ? "img" : ""}
              />
            </div>
            <div className="custom-software-banner-content-div">
              <div className="custom-software-banner-text">
                <div className="custom-software-banner-text-overlay">
                  <div
                    ref={ref2}
                    className={
                      inView2 ? "custom-software-banner-text-overlay-div" : ""
                    }
                  >
                    <span>
                      In today's digital age, technology is essential for
                      businesses to stay ahead of the competition. Businesses
                      need to be able to reach and engage with their customers
                      on all platforms, to avoid losing out on potential
                      business. At Sublime Technocorp, our innovative software
                      engineers can help you build a seamless, cloud-based web
                      application that will let you scale up and engage with
                      your customers easily.
                    </span>
                    <span>
                      We use cutting-edge technologies such as Node.JS,
                      React.JS, Angular, and Python, on AWS and Microsoft Azure
                      to meet all your custom cloud-based web app development
                      needs. So, if you're looking for a team of experts to help
                      you create a custom web app solution, look no further.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="custom-software-context-container">
          <img
            src={CustomSoftwareBuild}
            alt="Banner Img"
            className="custom-software-banner"
          />
          <div
            // ref={ref3}
            // className={inView3 ? "custom-software-context-text-overlay" : ""}
            className="custom-software-context-text-overlay"
          >
            <span>
              <h2>Cloud Application Development</h2>
              <p>
                A cloud-first approach to application development is key to
                ensuring you are building your product or application for the
                future. By having a cloud-first approach, you guarantee that
                businesses, systems, and applications have a digital operating
                model which helps businesses to deliver quickly and efficiently.
                <br />
                At Sublime Technocorp, we have perfected the art of cloud
                application development strategies. This helps us develop
                cloud-based applications faster and smoother. We can also help
                you strategize to modernize your legacy applications and move
                them to the cloud.
                <br />
                Our developers are experienced in using app development
                technologies such as Shopify and Magento, and they’ll make sure
                your e-commerce app is user-friendly and catches the eye of
                potential customers. We know how important it is to build
                customer loyalty and enhance inventory, and our developers will
                work hard to make sure your app meets all your needs.
              </p>
            </span>
          </div>
        </div>
        <CurvedText />
      </div>
    </>
  );
}
