import careerIcon1 from "../../assets/icons/location.svg";
import careerIcon2 from "../../assets/icons/badge.svg";
import careerIcon3 from "../../assets/icons/list.svg";
import careerIcon4 from "../../assets/icons/plane.svg";

export const careersCardData = [
  {
    icon: careerIcon1,
    careerTitle: "CLIENT REQUIREMENT",
    careerSubheading:
      "We assess and understand the technical skill requirements as well as the project requirements on a customised basis, plan and estimate the further needs.",
  },
  {
    icon: careerIcon2,
    careerTitle: "CANDIDATE SELECTION",
    careerSubheading:
      "We shortlist technical resources after screening them for their technical skills before presenting them to our clients. The resources shortlisted by our clients are then directed to specific training.",
  },
  {
    icon: careerIcon3,
    careerTitle: "TRAINING & ASSESSMENT",
    careerSubheading:
      "The training is carried out by certified trainers and weekly training assessments are depending on the clients' requirement yardsticks. The final training assessment is done through a written or application demo.",
  },
  {
    icon: careerIcon4,
    careerTitle: "CLIENT DEPLOYMENT",
    careerSubheading:
      "Post-training and assessment, the resource is deployed at the client site, having accomplished the set primary requirements. The resources are then put across a mentorship program for a fulfilling career prospect as well as long-term deployment at the client's site.",
  },
];
