export const DedicatedDevelopersData = [
  {
    title: "Dedicated Resources",
    subtitle:
      "Our dedicated developers work 8 hours a day on your project to make sure that it is completed successfully and to the highest standard. We assign no other projects to them before yours is finished so that we can avoid any possible errors or delays.",
  },
  {
    title: "Transparency in dealing",
    subtitle:
      "Clients can participate in daily scrums with the developers, so they are always kept in the loop about the project’s progress, and this eliminates the possibility of miscommunication.",
  },
  {
    title: "Quick Projects",
    subtitle:
      "Our dedicated developers’ team is time-efficient and known for being able to meet short deadlines – if you need us to ramp up the team to get your project done on time, reach out to us.",
  },
  {
    title: "Source Code Ownership",
    subtitle:
    {
      content: "You always have full code ownership and access to your code repository at any time. Developers check in code daily, so you can always check for new updates.",
      step1: "Step 1 – Share your requirements and request a quote",
      step2: "Step 2 – Interview shortlisted candidates over 2 rounds",
      step3: "Step 3 – Select a resource, complete formalities, and onboard",
    }
  },
];
