import React, { useState, useEffect } from "react";
import "./Navbar.scss";
import Logo from "../../assets/img/sublimenew2.png";
import { BsDashLg } from "react-icons/bs";
import { RxCross1 } from "react-icons/rx";
import { ReactComponent as MenuIcon } from "../../assets/icons/menu-icon.svg";

const config = { mass: 5, tension: 2000, friction: 200 };

export default function Navbar() {

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState("Home");
  const [toggle, set] = useState(true);


  const handleToggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const handleToggleServices = () => {
    setIsSubMenuOpen(!isSubMenuOpen);
  };

  useEffect(() => {
    if (isSidebarOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isSidebarOpen]);

  return (
    <>
      <div className="Navbar">
        <div className="company-logo">
          <a href="/">
            <img
              src={Logo}
              alt="Logo"
              width={190}
              height={105}
              className="company-logo"
            />
          </a>
        </div>
        <div>
          <button className="hamburger-button" onClick={handleToggleSidebar}>
           
            <div
              className="hamburger"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <span className={isHovered ? "wavy-text" : ""}>
                {isSidebarOpen
                  ? isHovered
                    ? "CLOSE"
                    : "MENU"
                  : isHovered
                  ? "OPEN"
                  : "MENU"}
              </span>
              {isHovered ? (
                isSidebarOpen ? (
                  <RxCross1 size={30} />
                ) : (
                  <BsDashLg size={30} />
                )
              ) : (
                <MenuIcon size={30} />
              )}
            </div>
          </button>
          <div onClick={() => set(state => !state)} className={`sidebar trails-main ${isSidebarOpen ? "open" : "closed"}`}>
            <div className="menu-cover-title header-container">Menu</div>
            <div className="sidebar-content">
              <img src={Logo} alt="Sublime Icon" width={190} height={105} />
              {/* <span> */}
              <a className="sidebar-item" href="/">
                Home
                <small style={{ fontSize: "12px" }}>01</small>
              </a>
              <a
                className="sidebar-item"
                href="/about-us"
                onClick={() => setCurrentPage("About")}
              >
                {currentPage === "About Us" ? "Current Page" : "About Us"}
                <small style={{ fontSize: "12px" }}>02</small>
              </a>
              <div
                className={`sidebar-item ${
                  isSubMenuOpen ? "open" : ""
                } services`}
                onClick={handleToggleServices}
              >
                Services
                <small style={{ fontSize: "12px" }}>03</small>
                {isSubMenuOpen && (
                  <div
                    className={`submenu-content ${isSubMenuOpen ? "open" : ""}`}
                  >
                  
                    <a href="/services/cloud-and-digital-transformation-service">
                      Cloud Transformation
                    </a>
                    <br />
                    <a href="/services/dedicated-developers">
                      Dedicated Developers
                    </a>
                    <br />
                    <a href="/services/custom-software-development">
                      Custom Software Development
                    </a>
                    <br />
                    <a href="/services/product-development-services">
                      Product Development Services
                    </a>
                    <br />
                    <a href="/services/data-analytics-services">
                      Data Analytics Services
                    </a>
                  </div>
                )}
              </div>
              <a href="/work" className="sidebar-item">
                Works
                <small style={{ fontSize: "12px" }}>04</small>
              </a>
              <a href="/careers" className="sidebar-item">
                Careers
                <small style={{ fontSize: "12px" }}>05</small>
              </a>
              <a href="/contact-us" className="sidebar-item">
                Contact Us
                <small style={{ fontSize: "12px" }}>06</small>
              </a>
              {/* </span> */}
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
  );
}
