import React, { useState } from "react";
import AboutUsBg from "../../assets/img/aboutusBg.webp";
import AboutSubDiv from "../../assets/img/WhyUsBg.jpg";
import "./AboutUs.scss";
import Partners from "../../components/home/Partners";
import ReactTyped from "react-typed";
import { skillsData } from "../../components/abtus/SkillsData";
import SkillsCard from "../../components/abtus/SkillsCard";
import { useInView } from "react-intersection-observer";
import { ReactComponent as Line } from "../../assets/icons/gradient_line.svg";
import { ReactComponent as Line2 } from "../../assets/icons/gradient lineL.svg";
import { CurvedText } from "../../components/home/ScrollPercentage";
import { Helmet } from "react-helmet";
export default function AboutUs() {
  const [selectedIndex, setSelectedIndex] = useState(9);

  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });
  const [expanded, setExpanded] = useState(false);
  const handleContentClick = (index) => {
    // Toggle the expanded state
    if (index === selectedIndex) {
      setSelectedIndex(9);

      setExpanded(!expanded);

      return;
    }
    setTimeout(() => {
      setSelectedIndex(index);
    }, 100);
    if (!expanded) setExpanded(!expanded);
    // if(selectedIndex != index )
    // setExpanded(!expanded);
  };
  return (
    <>
      <div>
        <Helmet>
          <title>
            Sublime Technocorp | Software And Web Development Company
          </title>
        </Helmet>
        <div className="aboutus-page">
          <div className="aboutus-container">
            <img src={AboutUsBg} alt="AboutUsBg" className="aboutus-bg" />
            <div className="aboutus-text-overlay">
              {/* <span className="aboutus-text-line"></span>
          <span className="aboutus-text-line"></span> */}

              <span>
                <Line2 className="line" />
                SUBLIME TECHNOCORP PVT LTD <Line className="line" />{" "}
              </span>
              <h2>SOFTWARE & WEB DEVELOPMENT COMPANY</h2>
              <span className="aboutus-text-span">
                <Line2 style={{ marginBottom: "15px" }} />
                <p>WE ARE YOUR ALLIES IN LEADING TECHNOLOGICAL FORAYS</p>
                <Line style={{ marginBottom: "15px" }} />
              </span>
            </div>
          </div>
          <div className="aboutus-content">
            <h1 className="aboutus-title">
              <ReactTyped
                strings={["Sublime Technocorp"]}
                typeSpeed={200}
                className="aboutus-animate"
              />
            </h1>
            <div className="aboutus-content-container">
              <h2>
                At Sublime Technocorp, we are a technology partner to medium and
                large organizations focused on innovative enterprise technology
                solutions development and consulting.
              </h2>
            </div>
          </div>
          <div className="aboutus-context">
            <img
              src={AboutSubDiv}
              alt="AboutSubDiv"
              className="aboutus-subdiv"
            />
            <div ref={ref} className={inView ? "aboutus-context-overlay" : ""}>
              <span>
                We are a leading software and web development company
                incorporated in the year 2012. We began with developing Websites
                and slowly enhanced our portfolio to software development, ERP
                software Development, IoT Development and AI Development. While
                we continue to offer all these services, our prime motive is to
                reform the way businesses operate and bring technology and
                automation to redefine and enhance repetitive processes and
                overall operations.
              </span>
              <br />
              <br />
              <span>
                We have catered to global clients across small, medium, and
                large organisations with our deep expertise in
                cost-effectiveness, delivery methodology, quality standards and
                timeliness. Our teams are highly skilled engineers from the most
                adaptable, advanced, and recent technologies. Our trust,
                commitment to excellence and expertise in the field helps us
                eliminate all possible hurdles and offer exceptional solutions.
              </span>
            </div>
          </div>
          <div className="our-skills-container">
            <div className="our-skills-title-content">
              <h1>Our Skills</h1>
            </div>
            <div className="our-skills-context">
              <div className="">
                {skillsData.map((skills, index) => (
                  <SkillsCard
                    key={index}
                    index={index}
                    title={skills.title}
                    subtitle={skills.subtitle}
                    onClick={handleContentClick}
                    show={index === selectedIndex}
                  />
                ))}
              </div>
            </div>
          </div>

          <Partners />
          <div className="contact-watermark">
            <h1>CONTACT</h1>
          </div>
          <CurvedText />
        </div>
      </div>
    </>
  );
}
