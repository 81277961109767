import React, { useState } from "react";
import "./WhyUscard.scss";
// import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemPanel, AccordionItemButton } from 'react-accordion';
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import CustomToggle from "react-bootstrap/AccordionButton";
// import CustomToggle from "react-bootstrap/"
import { MdKeyboardDoubleArrowDown } from "react-icons/md";
import { MdKeyboardArrowUp } from "react-icons/md";
import { DedicatedDevelopersData } from "./ServicesData";
export default function WhyUscard({ title, subtitle, show, onClick, index }) {
  const [openedIndex, setOpenedIndex] = useState(-1);


  const [clicked, setClicked] = useState("0");



  return (
    <>
      <div className="why-us-card">
        <div className="why-us-card-header">
          <div style={{ cursor: "pointer" }}>
            {show ? (
              <MdKeyboardArrowUp size={20} fill="#fff" />
            ) : (
              <MdKeyboardDoubleArrowDown size={20} fill="#fff" />
            )}
          </div>

          <span onClick={() => onClick(index)}>{title}</span>
        </div>
        <div className="why-us-card-content">
          {show && (
            <>
              {typeof subtitle === "string" ? (
                <p>{subtitle}</p>
              ) : (
                <>
                  {subtitle.content && <p>{subtitle.content}</p>}
                  {subtitle.step1 && <p>{subtitle.step1}</p>}
                  {subtitle.step2 && <p>{subtitle.step2}</p>}
                  {subtitle.step3 && <p>{subtitle.step3}</p>}
                </>
              )}
            </>
          )}
        </div>
      </div>
      
    </>
  );
}
