import React from "react";
import { FarmersConnectData } from "../../components/work/WorkpageT1Data";
import WorkpageT1 from "../../components/work/WorkpageT1";
export default function FarmersConnectApp() {
  return (
    <>
      {FarmersConnectData.map((work11) => (
        <WorkpageT1
          title={work11.title}
          bgImg={work11.bgImg}
          heading={work11.heading}
          boximg={work11.boximg}
          purposedescription={work11.purposedescription}
          benefitTitle={work11.benefitTitle}
          benefitsdescriptions={work11.benefitsdescriptions}
          benefits={work11.benefits}
          featureHeading={work11.featureHeading}
          featuredescription={work11.featuredescription}
          feature={work11.feature}
        />
      ))}
    </>
  );
}
