import React, { useState } from "react";
import DedicatedDevelopersBannerImg from "../../assets/img/services/dedicated-developers.jpg";
import DedicatedDevelopersBg from "../../assets/img/services/dedicated-developers-bg.jpg";
import DedicatedDevelopersWhyUs from "../../assets/img/services/dedicated-developers-why-us.jpg";
import DedicatedDevelopersWhyUsSmall from "../../assets/img/services/dedicated-developers-why-us-small.jpg";
import DedicatedDevelopersHireUs from "../../assets/img/services/commercial-glass-buildings-reflection-sun-rays-blue-web-header.jpg";
import { BsCheckLg } from "react-icons/bs";
import "./DedicatedDevelopers.scss";
import WhyUscard from "../../components/services/WhyUscard";
import { useInView } from "react-intersection-observer";
import { DedicatedDevelopersData } from "../../components/services/ServicesData";
import { CurvedText } from "../../components/home/ScrollPercentage";
import { ReactComponent as Line } from "../../assets/icons/gradient_line.svg";
import { ReactComponent as Line2 } from "../../assets/icons/gradient lineL.svg";
import { Helmet } from "react-helmet";
export default function DedicatedDevelopers() {
  const [selectedIndex, setSelectedIndex] = useState(9);
  const [ref1, inView1] = useInView({
    triggerOnce: true, // Ensures the animation only plays once
    threshold: 0.5, // Adjust the visibility threshold as needed
  });
  const [ref2, inView2] = useInView({
    triggerOnce: true, // Ensures the animation only plays once
    threshold: 0.5, // Adjust the visibility threshold as needed
  });
  const [ref3, inView3] = useInView({
    triggerOnce: true, // Ensures the animation only plays once
    threshold: 0.5, // Adjust the visibility threshold as needed
  });
  const [ref4, inView4] = useInView({
    triggerOnce: true, // Ensures the animation only plays once
    threshold: 0.5, // Adjust the visibility threshold as needed
  });

  const [ref5, inView5] = useInView({
    triggerOnce: true, // Ensures the animation only plays once
    threshold: 0.5, // Adjust the visibility threshold as needed
  });

  const [expanded, setExpanded] = useState(false);
  const handleContentClick = (index) => {
 
    if (index === selectedIndex) {
      setSelectedIndex(9);

      setExpanded(!expanded);

      return;
    }
    setTimeout(() => {
      setSelectedIndex(index);
    }, 100);
    if (!expanded) setExpanded(!expanded);

  };
  const mediaQuery = window.matchMedia("(max-width: 767px)");
  const imageStyle = {
    height: expanded ? (mediaQuery.matches ? "70vh" : "120vh") : "50vh",
    objectFit: "cover",
    transition: "linear 0.5s",


  };

  
  return (
    <>
     <Helmet>
        <title>Hire Dedicated Developers For Mobile & Web Development</title>
      </Helmet>
      <div className="dedicated-developers-page">
        <div className="dedicated-developers">
          <img
            src={DedicatedDevelopersBannerImg}
            alt="Dedicated Developers"
            className="dedicated-developers-img"
          />
          <div className="dedicated-developers-text-overlay">
            <span>
              <Line2 className="line-svg" />
              <h1>Dedicated Developers</h1>
              <Line className="line-svg" />
            </span>
          </div>
        </div>
        <div className="dedicated-developers-content">
          <div
            ref={ref1}
            className={inView1 ? "dedicated-developers-content-div" : ""}
          >
            <h1>Dedicated Developers</h1>
            <p
              ref={ref1}
              className={inView1 ? "dedicated-developers-content-div" : ""}
            >
              At Sublime Technocorp, we pride ourselves on being able to provide
              our clients with top Indian dedicated developers who have
              excellent communication skills. Our developers are experts in
              their field and can provide a great deal of value to start-ups,
              enterprises, and SMEs.
            </p>
          </div>
        </div>
        <div className="dedicated-developers-benefits-div">
          <div
            ref={ref2}
            className={inView2 ? "dedicated-developers-benefits-title" : ""}
          >
            <h2>
              Some benefits of hiring dedicated developers from Sublime
              Technologies include:
            </h2>
          </div>
          <div
            ref={ref2}
            className={inView2 ? "dedicated-developers-benefits-list" : ""}
          >
            <span>
              <li>Access to a large pool of highly skilled developers</li>
              <li>Cost-effective rates</li>
              <li>Increased flexibility and scalability</li>
              <li>Improved project management</li>
            </span>
          </div>
        </div>
        <div className="dedicated-developers-banner-container">
          <div
            ref={ref3}
            className={inView3 ? "dedicated-developers-banner-context" : ""}
          >
            <img
              src={DedicatedDevelopersBg}
              alt="Dedicated Developers Background"
            />
          </div>
          <div className="dedicated-developers-banner-content-div">
            <div className="dedicated-developers-banner-text">
              <div
                ref={ref3}
                className={
                  inView3 ? "dedicated-developers-banner-text-overlay" : ""
                }
              >
                <span>
                  At Sublime Technocorp we have handpicked talent with vast
                  experience and the best technical expertise. Whatever be your
                  requirement, we have technologies galore and the best
                  resources who can work onsite and remotely for you.
                  <br />
                  {/* <br /> */}
                  {/* <br /> */}
                  When your project is still evolving and undefined and needs
                  ongoing work, you can opt for these resources based on the
                  following modules -
                  <p>
                    <ul type="none" className="dedicated-developers-list-ul">
                      <li className="dedicated-developers-list-li">
                        {" "}
                        <BsCheckLg /> Hourly – Engage and pay on an hourly basis
                      </li>
                      <li className="dedicated-developers-list-li">
                        {" "}
                        <BsCheckLg /> Monthly – Engage and pay on a monthly
                        basis
                      </li>
                      <li className="dedicated-developers-list-li">
                        {" "}
                        <BsCheckLg /> Turnkey – Project-based engagement and
                        commercials
                      </li>
                    </ul>
                  </p>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="dedicated-developers-banner-context-why-us">
          <img
            src={DedicatedDevelopersHireUs}
            alt="Dedicated Developers Hire Us"
            className="dedicated-developers-hire-us-img"
          />
          <div
            ref={ref4}
            className={
              inView4 ? "dedicated-developers-context-text-overlay" : ""
            }
          >
            <h4>Hire On-Demand Dedicated Developers</h4>
            <p>
              At Sublime Technocorp, we have a team of experienced and dedicated
              developers who are skilled in mobile and web app development. Our
              developers are well-versed in new technologies and have the
              knowledge to deliver according to your requirements. We offer you
              the best developers to create a robust and scalable solution on
              your choice of the technology stack.
            </p>
          </div>
        </div>
        <div
          ref={ref5}
          className={inView5 ? "choose-developers-container" : ""}
        >
          <h2>Choose Developers In India as per your need</h2>
          <span>
            <div className="choose-developers-list">
              <ul>
                <li>Web Developer</li>
                <li>Mobile App Developer</li>
                <li>Front-end Developer</li>
                <li>Back-end Developer</li>
                <li>Game Developer</li>
              </ul>
            </div>
            <div className="choose-developers-list">
              <ul>
                <li>Full Stack Developer</li>
                <li>E-Commerce Developer</li>
                <li>CMS Developer</li>
                <li>DevOps Developer</li>
                <li>Blockchain Developer</li>
              </ul>
            </div>
          </span>
          <div className="choose-developers-context">
            If you’re looking for a dedicated team of developers to help, take
            your business to the next level, Sublime Technocorp is the perfect
            place for you. We specialize in helping start-ups and enterprises
            find the right talent to get the job done quickly and efficiently.
            Contact us today to learn more about our services !
          </div>
        </div>
        <div className="why-us-dedicated-developers-container">
          <div className="why-us-dedicated-developers">
            <img
              src={DedicatedDevelopersWhyUs}
              // style={{
              //   height: expanded ? "85vh" : "50vh",
              //   objectFit: "cover",
              //   transition: "linear 0.5s",
              // }}
              style={{
                ...imageStyle,
                // ...smallScreenStyle,
                // ...largeScreenStyle,
              }}
              alt="Dedicated Developers Why Us"
              className="dedicated-developers-why-us-img"
            />

            <div className="dedicated-developers-context-text-overlay-container">
              <div className="dedicated-developers-context-text-overlay-title">
                <h1>Why Us ? </h1>
              </div>
              <div className="dedicated-developers-context-text-overlay-content-div">
                {/* {expanded && ( */}
                <div className="dedicated-developers-context-text-overlay-content">
                  {DedicatedDevelopersData.map((developer, index) => (
                    <WhyUscard
                      key={index}
                      index={index}
                      show={index === selectedIndex}
                      title={developer.title}
                      subtitle={developer.subtitle}
                      onClick={handleContentClick}
                    />
                  ))}
                  {/* <WhyUscard /> */}
                </div>
                {/* )} */}
              </div>
            </div>
          </div>
        </div>
        <CurvedText />
      </div>
    </>
  );
}
