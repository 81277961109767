import React from "react";
import "./PrivacyPolicy.scss";
import Cover1 from "../../assets/img/privacypolicy/cover1.jpg";
import Cover2 from "../../assets/img/privacypolicy/cover2.jpg";
import Cover3 from "../../assets/img/privacypolicy/cover3.jpg";
import Cover4 from "../../assets/img/privacypolicy/cover4.jpg";
import Cover5 from "../../assets/img/privacypolicy/cover5.webp";
import Cover6 from "../../assets/img/privacypolicy/cover6.jpg";
import Cover7 from "../../assets/img/privacypolicy/cover7.jpg";
import Cover8 from "../../assets/img/privacypolicy/cover8.jpg";
import Cover9 from "../../assets/img/privacypolicy/cover9.jpg";
import Cover10 from "../../assets/img/privacypolicy/cover10.jpg";
import Cover11 from "../../assets/img/privacypolicy/cover11.jpg";
import Cover12 from "../../assets/img/privacypolicy/cover12.webp";
import Cover13 from "../../assets/img/privacypolicy/cover13.jpg";
import Cover14 from "../../assets/img/privacypolicy/cover14.jpg";
import { useInView } from "react-intersection-observer";
import { CurvedText } from "../../components/home/ScrollPercentage";
import { Helmet } from "react-helmet";
export default function PrivacyPolicy() {
  const [ref1, inView1] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });
  const [ref2, inView2] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });
  const [ref3, inView3] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });
  const [ref4, inView4] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });
  const [ref5, inView5] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });
  const [ref6, inView6] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });
  const [ref7, inView7] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });
  const [ref8, inView8] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });
  const [ref9, inView9] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });
  const [ref10, inView10] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });
  const [ref11, inView11] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });
  const [ref12, inView12] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });
  const [ref13, inView13] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });
  const [ref14, inView14] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  return (
    <>
    <Helmet>
      <title>Privacy Policy | Sublime Technocorp Pvt Ltd</title>
    </Helmet>
      <div className="privacy-policy-page">
        <div className="privacy-policy-container">
          <img src={Cover1} alt="cover1" className="privacy-policy-cover" />
          <div
            ref={ref1}
            className={inView1 ? "privacy-policy-content-text-overlay" : ""}
          >
            <div className="privacy-policy-content">
              <h1>Privacy Policy</h1>
            </div>
          </div>
        </div>
        <div className="privacy-policy-container">
          <img
            src={Cover2}
            alt="cover2"
            className="privacy-policy-cover-detail"
          />
          <div
            ref={ref2}
            className={inView2 ? "privacy-policy-content-text-overlay" : ""}
          >
            <h3>Privacy & Policy</h3>
            <p>
              This Privacy Policy explains what information is collected from
              visitors to sublimetechnocorp.com, why the information is
              collected and how it is used. It also explains user options for
              accessing and managing information, our data security practices,
              and other matters. sublimetechnocorp.com is operated and owned by
              Sublime Technocorp Pvt Ltd. This Policy is subject to our Terms of
              Use
            </p>
          </div>
        </div>
        <div className="privacy-policy-container">
          <img
            src={Cover3}
            alt="cover1"
            className="privacy-policy-cover-detail"
          />
          <div
            ref={ref3}
            className={inView3 ? "privacy-policy-content-text-overlay" : ""}
          >
            <h3>Personal Information Collection And Reasons Thereof</h3>
            <p>
              We collect personal information from you to identify you, to
              provide you with the information required to serve you, to ensure
              that we provide you with the correct feedback and to help us
              deliver a superior educational experience. Information collected
              enables us to give you convenient access to our service offerings
              and products and may help us tailor your educational experience.
            </p>
          </div>
        </div>
        <div className="privacy-policy-container">
          <img
            src={Cover4}
            alt="cover1"
            className="privacy-policy-cover-detail"
          />
          <div
            ref={ref4}
            className={inView4 ? "privacy-policy-content-text-overlay" : ""}
          >
            <h3>Information You Provide</h3>
            <p>
              We collect information to provide more effective services. We ask
              for your name, email address, and other personal information when
              you register to use certain features of our website. We also may
              ask for this information at other times, such as when you enter
              contests or other promotions sponsored by us and / or our
              partners. In future, if you use a feature that requires payment of
              a fee, we collect your credit card data or payment account
              information. When you use one of our paid products, we track the
              web pages, and information that has been accessed by you, and
              store it on our servers. This enables us to track items that you
              have completed and those that you need to see.
            </p>
          </div>
        </div>
        <div className="privacy-policy-container">
          <img
            src={Cover5}
            alt="cover1"
            className="privacy-policy-cover-detail"
          />
          <div
            ref={ref5}
            className={inView5 ? "privacy-policy-content-text-overlay" : ""}
          >
            <h3>Cookies</h3>
            <p>
              We send cookies that are small files containing a string of
              characters to your computer, thereby uniquely identifying your
              browser. Cookies are used to track your preferences, help you log
              in faster, and aggregated to determine user trends. This data is
              used to improve our offerings, such as providing more content in
              areas of greater interest to a majority of users. Most browsers
              are initially set up to accept cookies, but you can reset your
              browser to refuse all cookies or to indicate when a cookie is
              being sent. Some of our features and services may not function
              properly if your cookies are disabled.
            </p>
          </div>
        </div>
        <div className="privacy-policy-container">
          <img
            src={Cover6}
            alt="cover1"
            className="privacy-policy-cover-detail"
          />
          <div
            ref={ref6}
            className={inView6 ? "privacy-policy-content-text-overlay" : ""}
          >
            <h3>Log Information</h3>
            <p>
              When you access our website, our servers automatically record
              information that your browser sends whenever you visit a website.
              These server logs may include information such as your web
              request, Internet Protocol address, browser type, browser
              language, the date and time of your request and one or more
              cookies that may uniquely identify your browser.
            </p>
          </div>
        </div>
        <div className="privacy-policy-container">
          <img
            src={Cover7}
            alt="cover1"
            className="privacy-policy-cover-detail"
          />
          <div
            ref={ref7}
            className={inView7 ? "privacy-policy-content-text-overlay" : ""}
          >
            <h3>User Communications</h3>
            <p>
              When you send an email or other communication to us, we may retain
              those communications in order to process your inquiries, respond
              to your requests and improve our services.
            </p>
          </div>
        </div>
        <div className="privacy-policy-container">
          <img
            src={Cover8}
            alt="cover8"
            className="privacy-policy-cover-detail"
          />
          <div
            ref={ref8}
            className={inView8 ? "privacy-policy-content-text-overlay" : ""}
          >
            <h3>Links</h3>
            <p>
              We may present links in a format that enables us to keep track of
              whether these links have been followed. We use this information to
              improve our customized content. Clicking on links may take you to
              sites outside our domain. We are not responsible for the privacy
              practices of other websites. We encourage our users to be aware
              when they leave our site to read the privacy statements of each
              and every web site that collects personally identifiable
              information. This Privacy Policy applies solely to information
              collected by our Website.
            </p>
          </div>
        </div>
        <div className="privacy-policy-container">
          <img
            src={Cover9}
            alt="cover1"
            className="privacy-policy-cover-detail"
          />
          <div
            ref={ref9}
            className={inView9 ? "privacy-policy-content-text-overlay" : ""}
          >
            <h3>Alerts</h3>
            <p>
              We may alert you by email or phone through SMS, MMS or call or
              push messages through the web application to inform you about new
              service offerings or other information which we feel might be
              useful for you
            </p>
          </div>
        </div>
        <div className="privacy-policy-container">
          <img
            src={Cover10}
            alt="cover1"
            className="privacy-policy-cover-detail"
          />
          <div
            ref={ref10}
            className={inView10 ? "privacy-policy-content-text-overlay" : ""}
          >
            <h3>Public Forums</h3>
            <p>
              When you use certain features on our website like the discussion
              forums and you post or share your personal information such as
              comments, messages, files, photos, will be available to all users,
              and will be in the public domain. All such sharing of information
              is done at your own risk. Please keep in mind that if you disclose
              personal information in your profile or when posting on our forums
              this information may become publicly available.
            </p>
          </div>
        </div>
        <div className="privacy-policy-container">
          <img
            src={Cover11}
            alt="cover1"
            className="privacy-policy-cover-detail"
          />
          <div
            ref={ref11}
            className={inView11 ? "privacy-policy-content-text-overlay" : ""}
          >
            <h3>Data Security</h3>
            <p>
              If you use a feature requiring payment of a fee, we transmit your
              credit card data to our bank for processing through a secured
              payment gateway. If we encounter a problem with payment, we may
              review the order information with you and our bank to resolve it.
              We do not otherwise disclose your credit card information. We do
              not share your email address or your personal information with
              third-party marketers. We offer secure pages to collect sensitive
              information on our order form, such as credit card information. We
              also use administrative, physical and technical precautions to
              help protect the confidentiality, security, and integrity of
              personal information stored on our system. We host the Site at a
              commercial grade data center that employs extensive security
              practices. While no computer system is completely secure, we
              believe the measures implemented by our Site reduce the likelihood
              of security problems to a level appropriate to the type of data
              involved.
            </p>
          </div>
        </div>
        <div className="privacy-policy-container">
          <img
            src={Cover12}
            alt="cover1"
            className="privacy-policy-cover-detail"
          />
          <div
            ref={ref12}
            className={inView12 ? "privacy-policy-content-text-overlay" : ""}
          >
            <h3>Information Sharing</h3>
            <p>
              Other than the above mentioned sharing of data related to credit
              card information, we only share personal information with other
              companies or individuals outside of Sublime Technocorp Pvt Ltd in
              the following limited circumstances. We have a good faith belief
              that access, use, preservation or disclosure of such information
              is reasonably necessary to: 1.Satisfy any applicable law,
              regulation, legal process or enforceable governmental request.
              2.Enforce applicable Terms of Use, including investigation of
              potential violations thereof. 3.Detect, prevent, or otherwise
              address fraud, security or technical issues. 4.Protect against
              imminent harm to the rights, property or safety of
              sublimetechnocorp.com or Sublime Technocorp Pvt Ltd, its users or
              the public as required or permitted by law. We may share with
              third parties certain pieces of aggregated, non-personal
              information, such as the number of users subscribed to a
              particular product, or the number of users registered on our
              website. Such information does not identify you individually. We
              may provide information to service providers to help us bring you
              the services we offer.
            </p>
          </div>
        </div>
        <div className="privacy-policy-container">
          <img
            src={Cover13}
            alt="cover1"
            className="privacy-policy-cover-detail"
          />
          <div
            ref={ref13}
            className={inView13 ? "privacy-policy-content-text-overlay" : ""}
          >
            <h3>Data Retentions</h3>
            <p>
              Specifically, we may use third parties to facilitate our business,
              such as to host the service at a co-location facility for servers.
              Where we utilize third parties for the processing of any personal
              information, we implement reasonable contractual and technical
              protections limiting the use of that information to our specified
              purposes. Currently, we plan to retain user data while an account
              is active and for at least three years afterward. We may alter
              this practice according to legal and business requirements. For
              example, we may lengthen the retention period for some data if
              needed to comply with the law or voluntary codes of conduct.
              Unless otherwise prohibited, we may shorten the retention period
              for some types of data if needed to free up storage space.
            </p>
          </div>
        </div>
        <div className="privacy-policy-container">
          <img
            src={Cover14}
            alt="cover1"
            className="privacy-policy-cover-detail"
          />
          <div
            ref={ref14}
            className={inView14 ? "privacy-policy-content-text-overlay" : ""}
          >
            <h3>Site Visibility</h3>
            <p>
              We might add video, audio or text testimonials to the site for
              display wherever necessary along with the photographs. In this
              event, we shall collect the basic information from you on writing,
              email, message from your authorized account with consent. Your
              consent to this privacy policy document by accessing our website,
              you agree to this Privacy Policy. This document supersedes any
              prior communication on this topic and reflects the entire and
              exclusive privacy policy for this Site. This policy is subject to
              our terms of use, which take precedence over any conflicting
              policy provision. We may change our policy by posting a new
              version of it on our Site. Team Sublime Technocorp Pvt Ltd, If
              there are any questions regarding this privacy policy you may
              contact us by writing to us. We are located at:
            </p>
          </div>
        </div>
        <CurvedText />
      </div>
    </>
  );
}
