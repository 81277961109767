import React from "react";
import { OnlineLaundaryData } from "../../components/work/WorkpageT1Data";
import WorkpageT1 from "../../components/work/WorkpageT1";
export default function OnlineLaundary() {
  return (
    <>
      {OnlineLaundaryData.map((work7) => (
        <WorkpageT1
          title={work7.title}
          bgImg={work7.bgImg}
          heading={work7.heading}
          purposedescription={work7.purposedescription}
          benefitTitle={work7.benefitTitle}
          benefits={work7.benefits}
          boximg={work7.boximg}
          featureHeading={work7.featureHeading}
          featuredescription={work7.featuredescription}
          feature={work7.feature}
        />
      ))}
    </>
  );
}
