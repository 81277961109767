import React from "react";
import "./WorkCard.scss";
import { BsArrowUpRight } from "react-icons/bs";

export default function WorkCard({ title, coverimg, worklink }) {
  return (
    <>
      <div className="work-card-width">
        <div className="work-card-container">
          <img src={coverimg} alt={title} className="work-card-img" />
          {/* <div className='work-card-overlay-div'> */}

          <div className="work-card-text-overlay">
            <h2>{title}</h2>
            <span>
              <a href={worklink}>VIEW PROJECT</a>
              <BsArrowUpRight size={22} className="work-card-icon" />
            </span>
          </div>
          {/* </div> */}
        </div>
      </div>
    </>
  );
}
