import React from "react";
import { SportsEcosystemData } from "../../components/work/WorkpageT1Data";
import WorkpageT1 from "../../components/work/WorkpageT1";
export default function SportsEcosystem() {
  return (
    <>
      {SportsEcosystemData.map((work9) => (
        <WorkpageT1
          bgImg={work9.bgImg}
          title={work9.title}
          heading={work9.heading}
          boximg={work9.boximg}
          purposedescription={work9.purposedescription}
          benefitTitle={work9.benefitTitle}
          benefitsdescriptions={work9.benefitsdescriptions}
          benefits={work9.benefits}
          featureHeading={work9.featureHeading}
          featuredescription={work9.featuredescription}
          feature={work9.feature}
        />
      ))}
    </>
  );
}
